import { memo, useEffect, useState, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipPlayer from '../Clip/ClipPlayer/ClipPlayer';
import ClipPlayerWrapper from '../Clip/ClipPlayer/ClipPlayerWrapper';
import { TIMELINE_DISPLAY_TYPE, TRANSCRIPT_DISPLAY_TYPE } from '../Clip/ClipPlayer/ClipTimelineV2/ClipTimelineTypes';
import { allClips } from '@/stores/clip';
import { Clip } from '@/domains/asset';

function RecordingsPage() {
  const allClipsStore = useSyncExternalStore(allClips.subscribe, allClips.getSnapshot);

  const [activeClip, setActiveClip] = useState<Clip | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!allClipsStore.length) {
      return;
    }

    // TODO @dusangc: Replace with actual value once BE is merged
    const clipId = '2bb53b8a-edae-43bf-b0a3-de617679c619';
    const activeClip = allClipsStore.find(clip => clip.id === clipId) ?? allClipsStore[0];
    setActiveClip(activeClip ?? null);
  }, [allClipsStore]);

  const handleTranscriptError = (err: any) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  };

  return (
    <div className="flex h-full overflow-hidden">
      <div className="flex grow flex-col overflow-hidden transition-all duration-[400ms] ease-in-out">
        <div className="relative ml-1 flex h-full grow flex-col">
          {activeClip?.id && (
            <ClipPlayerWrapper clipId={activeClip.id} handleTranscriptError={handleTranscriptError}>
              <div className="h-full">
                <ClipPlayer
                  playerClasses="lg:p-16"
                  inlineEditEnabled={false}
                  showNewTimeline={!activeClip.locked}
                  timelineDisplayType={
                    !activeClip.locked ? TIMELINE_DISPLAY_TYPE.FILMSTRIP : TIMELINE_DISPLAY_TYPE.PROGRESS_BAR
                  }
                  showClipHeader={true}
                  transcriptDisplayType={TRANSCRIPT_DISPLAY_TYPE.FULL}
                />
              </div>
            </ClipPlayerWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(RecordingsPage);
