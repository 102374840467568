import { Fragment, useSyncExternalStore } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { getDisabledStatesAndTitle, getFaceLocationUrl } from './FaceSpeakerTogglerUtils';
import { updateVisibleFaceIds } from '@/stores/speakersAnalysis/speakersAnalysis';
import { classnames } from '@/libs/utils';
import Toggle from '@/components/atoms/Toggle';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { core } from '@/stores/core';

// TODO @dusangc: Remove isInline prop with Easy Clip Customizer FF cleanup, it will be always true
export default function FaceToggler({ isInline = false }: { isInline?: boolean }) {
  const transcriptStore = useTranscriptContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const { clipId, speakersAnalysis } = useClipsContext();

  function triggerSpeakerEdit(speakerId?: string) {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }
  return (
    <Fragment>
      <div
        className={classnames('rounded-md', isInline ? '' : 'max-h-[260px] overflow-y-scroll border border-slate-200')}
      >
        {speakersAnalysis.face_ids.map(faceId => {
          const { isToggleDisabled, toggleDisabledTitle } = getDisabledStatesAndTitle(speakersAnalysis, faceId);

          const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker =>
            speakersAnalysis.speaker_mapping?.[speaker.id]?.includes(faceId)
          );

          const speakerName = speaker ? `${speaker.first_name} ${speaker.last_name}` : 'Unidentified Speaker';

          return (
            <div className="rounded-md px-3 py-2" key={faceId}>
              <div className="flex items-center gap-2">
                <div
                  className="group flex w-full cursor-pointer flex-row items-center gap-2"
                  onClick={() => triggerSpeakerEdit(speaker?.id)}
                >
                  <img
                    src={getFaceLocationUrl(coreStore.content!.project_id, coreStore.content!.id, clipId!, faceId)}
                    alt={faceId.toString()}
                    className={classnames(
                      'aspect-square h-12 w-12 rounded-full border border-slate-200 bg-cover bg-center'
                    )}
                  />

                  <span className="text-sm">{speakerName}</span>
                  <div className="hidden group-hover:block">
                    <IconEdit size={16} />
                  </div>
                </div>
                <div className="ml-auto shrink-0">
                  <Toggle
                    on={speakersAnalysis.visible_face_ids.includes(faceId)}
                    onToggle={() => {
                      if (
                        speakersAnalysis.visible_face_ids.includes(faceId) &&
                        speakersAnalysis.visible_face_ids.length > 1
                      ) {
                        updateVisibleFaceIds(
                          clipId,
                          speakersAnalysis.visible_face_ids.filter(id => id !== faceId)
                        );
                      } else if (speakersAnalysis.visible_face_ids.length < 4) {
                        updateVisibleFaceIds(clipId, [...speakersAnalysis.visible_face_ids, faceId]);
                      }
                    }}
                    disabled={isToggleDisabled}
                    title={toggleDisabledTitle}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}
