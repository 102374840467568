import {
  CaptionAttributes,
  CropPositions,
  DrawVideoOptions,
  OCCUPANCY_VARIATION,
  PillBoxSizes,
  TextWidthCalculationResult
} from './types';
import { getCanvasTextDimensions, getNewValuesForSpeakerCrops } from './util';
import { LayoutType, SizeType } from '@/domains/asset';
import { getCurrentClipFontName } from '@/stores/brandKit';
import { getCentreCropValues } from '@/Pages/Clip/CanvasPlayer/CanvasPlayerUtils';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { currentClip } from '@/stores/clip';
import { getFontByLocation } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { loadFont } from '@/libs/fonts';
import { AdjustedBoxBoundary, CropPosition } from '@/stores/speakersAnalysis/speakersAnalysisTypes';

export abstract class SizeConfig {
  protected abstract type: SizeType;
  protected clipId: string;
  protected devicePixelRatio: number;
  protected clipLayout: LayoutType;

  protected width: number;
  protected height: number;

  protected abstract VIDEO_SURROUND_PADDING: number;
  protected abstract VIDEO_BOTTOM_PADDING: number;

  protected abstract CAPTIONS_LEFT_MARGIN: number;
  protected abstract CAPTIONS_RIGHT_MARGIN: number;
  protected abstract CAPTIONS_BOTTOM_MARGIN: number;
  protected abstract CAPTIONS_FONT_SIZE: number;

  protected BORDER_VIDEO_RADIUS = 0;

  protected readonly PILL_MAX_WIDTH_RATIO = 0.7;

  protected abstract AUDIOGRAM_DIMENSIONS: {
    IMG_DIMENSIONS_RATIO: {
      height: number;
      width: number;
    };
    WAVEFORM_HEIGHT_MULTIPLIER: number;
    WAVEFORM_POSITION: {
      x: number;
      y: number;
    };
    SPEAKER_LABEL_WIDTH_MULTIPLIER: number;
  };

  constructor(height: number, width: number, clipId: string, clipLayout: LayoutType, devicePixelRatio?: number) {
    this.devicePixelRatio = devicePixelRatio || window.devicePixelRatio || 1;
    this.clipId = clipId;
    this.clipLayout = clipLayout;
    this.height = height * this.devicePixelRatio;
    this.width = width * this.devicePixelRatio;
  }

  getWidth(): number {
    return this.width;
  }

  getHeight(): number {
    return this.height;
  }

  getHeightAndWidth(): { height: number; width: number } {
    return { height: this.height, width: this.width };
  }

  getAudiogramImageDimensionsRatio(): {
    height: number;
    width: number;
  } {
    return this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO;
  }

  abstract getAudiogramWaveformAttributes({ width, height }: { width: number; height: number }): {
    top: number;
    left: number;
    waveFormHeight: number;
  };

  abstract getPillMaxWidth(totalNumber: number): number;

  abstract getSizeGridPositions(
    totalVideos: number,
    currentVideoNumber: number,
    target_crop_x: number,
    target_crop_y: number,
    target_crop_width: number,
    target_crop_height: number
  ): {
    target_crop_x: number;
    target_crop_y: number;
    target_crop_width: number;
    target_crop_height: number;
  };

  getVideoSurroundPadding(): number {
    return this.VIDEO_SURROUND_PADDING * this.devicePixelRatio;
  }

  getVideoBottomPadding(_?: { shouldUseDefaultLayoutValues: boolean }): number {
    return this.VIDEO_BOTTOM_PADDING * this.devicePixelRatio;
  }

  getCanvasTextSizes(
    totalNumber: number,
    name: string,
    title: string,
    fontName: string | null
  ): TextWidthCalculationResult {
    const { nameSize, titleSize } = this.getSpeakerNameTitlePillTextSizes(totalNumber);
    const maxWidth = this.getPillMaxWidth(totalNumber);
    return getCanvasTextDimensions({
      maxWidth,
      name,
      title,
      nameFont: `${nameSize}px ${fontName || 'Inter'}`,
      titleFont: `${titleSize}px ${fontName || 'Inter'}`,
      canvasHeight: this.getHeight(),
      canvasWidth: this.getWidth()
    });
  }

  abstract getPillBoxSizes(
    currentVideoNumber: number,
    totalNumber: number,
    canvasPillWidth: number,
    isTruncated: boolean
  ): PillBoxSizes;

  abstract getSpeakerNameTitlePillTextSizes(
    totalNumber: number,
    correctionFactor?: number
  ): {
    nameSize: number;
    titleSize: number;
  };

  getAudiogramDimensions() {
    return this.AUDIOGRAM_DIMENSIONS;
  }

  getTargetValuesForDefaultLayout({
    showCaption,
    videoHeight,
    videoWidth,
    shouldUseDefaultLayoutValues
  }: RequiredPick<DrawVideoOptions, 'showCaption' | 'videoHeight' | 'videoWidth' | 'shouldUseDefaultLayoutValues'>) {
    const { width: canvasWidth, height: canvasHeight } = this.getHeightAndWidth();
    let target_crop_x = 0;
    let target_crop_y = 0;

    let target_crop_width = canvasWidth;
    let target_crop_height = canvasHeight;

    let videoSurroundPadding = this.getVideoSurroundPadding();
    let videoBottomPadding = this.getVideoBottomPadding({
      shouldUseDefaultLayoutValues
    });

    const videoAspectRatio = videoWidth / videoHeight;

    if (!showCaption) {
      videoSurroundPadding = 0;
      videoBottomPadding =
        // TODO: @AshwinBhatkal - Move this to invididual sizeConfigs
        // Things in mind right now
        // 1. Probably handle based on aspect ratios
        // 2. Will require reliance on bottom padding to be removed for placing video and captions
        this.type === 'LANDSCAPE' ? 0 : (target_crop_height - target_crop_width / videoAspectRatio) / 2;
    }

    if (target_crop_width > target_crop_height) {
      target_crop_y += videoSurroundPadding;
      target_crop_height -= videoBottomPadding;

      const auxillary_target_crop_width = target_crop_width - videoSurroundPadding * 2;
      target_crop_width = target_crop_height * videoAspectRatio;
      const availableSpace = auxillary_target_crop_width - target_crop_width;

      target_crop_x += videoSurroundPadding + availableSpace / 2;
    } else {
      target_crop_x += videoSurroundPadding;
      target_crop_width -= videoSurroundPadding * 2;

      const auxillary_target_crop_height = target_crop_width / videoAspectRatio;

      target_crop_y = target_crop_height - videoBottomPadding - auxillary_target_crop_height;
      target_crop_height = auxillary_target_crop_height;
    }

    /**
     * START - https://goldcast.atlassian.net/browse/CR-1618
     * A negative crop_x, crop_y or a crop height/width greater than canvas height/width
     * would mean that small part of the video needs to be shifted to fit the canvas.
     * These are cases when the dimensions of the uploaded video are off by a marginal
     * value from the canvas dimensions. See above ticket for reference */

    if (target_crop_x < 0) {
      target_crop_x = 0;
    }

    if (target_crop_y < 0) {
      target_crop_y = 0;
    }

    if (target_crop_width > canvasWidth) {
      target_crop_width = canvasWidth;
    }

    if (target_crop_height > canvasHeight) {
      target_crop_height = canvasHeight;
    }
    // END - https://goldcast.atlassian.net/browse/CR-1618

    return {
      target_crop_x,
      target_crop_y,
      target_crop_width,
      target_crop_height
    };
  }

  getSourceCropValuesForSpeakerLayouts(
    target_crop_x: number,
    target_crop_y: number,
    target_crop_width: number,
    target_crop_height: number,
    optionsForNewSpeakerMapping: Required<DrawVideoOptions>['optionsForNewSpeakerMapping'],
    adjustedBoxBoundary: AdjustedBoxBoundary = {
      boundary_left_x: 0,
      boundary_right_x: 1,
      boundary_top_y: 0,
      boundary_bottom_y: 1
    }
  ) {
    let variation: OCCUPANCY_VARIATION;
    let boundingBoxInConsideration: CropPosition, percentageOfMainDimensionToOccupy: number;

    const { allPositions, originalVideoWidth, originalVideoHeight } = optionsForNewSpeakerMapping;

    const faceBoundingBox = allPositions?.crop_position_face;
    const personBoundingBox = allPositions?.crop_position_person;

    const isContainerPortrait = target_crop_height > target_crop_width;

    // If there is no person bound, then use face bound
    if (!personBoundingBox) {
      variation = isContainerPortrait ? OCCUPANCY_VARIATION.OCCUPY_WIDTH : OCCUPANCY_VARIATION.OCCUPY_HEIGHT;
      boundingBoxInConsideration = faceBoundingBox;
      percentageOfMainDimensionToOccupy = 0.5;
    } else if (isContainerPortrait) {
      variation = OCCUPANCY_VARIATION.OCCUPY_HEIGHT;
      boundingBoxInConsideration = personBoundingBox;
      percentageOfMainDimensionToOccupy = 1;
    } else {
      variation = OCCUPANCY_VARIATION.OCCUPY_HEIGHT;
      boundingBoxInConsideration = personBoundingBox;
      percentageOfMainDimensionToOccupy = 1;
    }

    // original values wrt the main video
    const original_crop_width = Math.round(
      (boundingBoxInConsideration?.bottom_right.x - boundingBoxInConsideration?.top_left.x) * originalVideoWidth
    );
    const original_crop_height = Math.round(
      (boundingBoxInConsideration?.bottom_right.y - boundingBoxInConsideration?.top_left.y) * originalVideoHeight
    );
    const original_crop_x = Math.round(boundingBoxInConsideration?.top_left.x * originalVideoWidth);
    const original_crop_y = Math.round(boundingBoxInConsideration?.top_left.y * originalVideoHeight);

    // depending on variation, choose the dimension to work with and occupy
    const targetDimensionInQuestion =
      variation === OCCUPANCY_VARIATION.OCCUPY_WIDTH ? target_crop_width : target_crop_height;
    const originalDimensionInQuestion =
      variation === OCCUPANCY_VARIATION.OCCUPY_WIDTH ? original_crop_width : original_crop_height;

    // since we are placing the video into a percentage of the target(canvas), we need to find the multiplier
    // all video/original values with be multiplied by this multiplier to get the target values and vice versa
    const multiplier = (percentageOfMainDimensionToOccupy * targetDimensionInQuestion) / originalDimensionInQuestion;

    const newWidthCropValues = getNewValuesForSpeakerCrops({
      original_crop_dimension: original_crop_x,
      original_crop_dimension_length: original_crop_width,
      target_crop_dimension: target_crop_x,
      target_crop_dimension_length: target_crop_width,
      multiplier,
      originalVideoDimension: originalVideoWidth,
      boundingBoxProps: {
        boundingBoxStartInDimension: boundingBoxInConsideration.top_left.x,
        boundingBoxEndInDimension: boundingBoxInConsideration.bottom_right.x
      },
      faceProps: {
        faceStartInDimension: faceBoundingBox.top_left.x,
        faceEndInDimension: faceBoundingBox.bottom_right.x
      },
      adjustedOriginalVideoDimensionStart: adjustedBoxBoundary.boundary_left_x,
      adjustedOriginalVideoDimensionEnd: adjustedBoxBoundary.boundary_right_x
    });

    const newHeightCropValues = getNewValuesForSpeakerCrops({
      original_crop_dimension: original_crop_y,
      original_crop_dimension_length: original_crop_height,
      target_crop_dimension: target_crop_y,
      target_crop_dimension_length: target_crop_height,
      multiplier,
      originalVideoDimension: originalVideoHeight,
      boundingBoxProps: {
        boundingBoxStartInDimension: boundingBoxInConsideration.top_left.y,
        boundingBoxEndInDimension: boundingBoxInConsideration.bottom_right.y
      },
      faceProps: {
        faceStartInDimension: faceBoundingBox.top_left.y,
        faceEndInDimension: faceBoundingBox.bottom_right.y
      },
      adjustedOriginalVideoDimensionStart: adjustedBoxBoundary.boundary_top_y,
      adjustedOriginalVideoDimensionEnd: adjustedBoxBoundary.boundary_bottom_y
    });

    return {
      crop_x: newWidthCropValues.crop_dimension,
      crop_width: newWidthCropValues.crop_dimension_length,
      new_target_crop_x: newWidthCropValues.new_target_crop_dimension,
      new_target_crop_width: newWidthCropValues.new_target_crop_dimension_length,
      crop_y: newHeightCropValues.crop_dimension,
      crop_height: newHeightCropValues.crop_dimension_length,
      new_target_crop_y: newHeightCropValues.new_target_crop_dimension,
      new_target_crop_height: newHeightCropValues.new_target_crop_dimension_length
    };
  }

  getCropPositions(drawVideoOptions: DrawVideoOptions): CropPositions & {
    applyNewCropping: boolean;
  } {
    const {
      videoWidth,
      videoHeight,
      showCaption,
      source_crop_x_offset = 0,
      source_crop_y_offset = 0,
      currentVideoNumber = 1,
      totalVideos = 1,
      centreCropOnly,
      optionsForNewSpeakerMapping,
      shouldUseDefaultLayoutValues = false,
      adjustedBoxBoundary = {
        boundary_left_x: 0,
        boundary_right_x: 1,
        boundary_top_y: 0,
        boundary_bottom_y: 1
      }
    } = drawVideoOptions;

    const featureFlagsValues = featureFlagStore.getSnapshot();
    const metadata = drawVideoOptions.clipMetadata ?? currentClip.getSnapshot()[this.clipId].asset_metadata;
    const shouldShowStaticCaptions =
      featureFlagsValues[FeatureFlagKeys.Use_CL_Captions_Overlay] === false
        ? showCaption
        : metadata.hide_borders === undefined // handle old clips
        ? showCaption
        : !metadata.hide_borders;

    let target_crop_x = 0;
    let target_crop_y = 0;
    let target_crop_width = this.getWidth();
    let target_crop_height = this.getHeight();

    const videoSurroundPadding = this.getVideoSurroundPadding();
    const videoBottomPadding = this.getVideoBottomPadding({
      shouldUseDefaultLayoutValues
    });

    if (!centreCropOnly) {
      if (shouldUseDefaultLayoutValues) {
        const result = this.getTargetValuesForDefaultLayout({
          showCaption: shouldShowStaticCaptions,
          videoHeight,
          videoWidth,
          shouldUseDefaultLayoutValues
        });
        target_crop_x = result.target_crop_x;
        target_crop_y = result.target_crop_y;
        target_crop_width = result.target_crop_width;
        target_crop_height = result.target_crop_height;
      } else if (shouldShowStaticCaptions || totalVideos > 1) {
        target_crop_x += videoSurroundPadding;
        target_crop_y += videoSurroundPadding;
        target_crop_width -= videoSurroundPadding * 2;
        // This is required here as speaker grid has padding by default
        target_crop_height -= shouldShowStaticCaptions ? videoBottomPadding : videoSurroundPadding * 2;
      }

      if (totalVideos > 1) {
        const gridSizePositions = this.getSizeGridPositions(
          totalVideos,
          currentVideoNumber,
          target_crop_x,
          target_crop_y,
          target_crop_width,
          target_crop_height
        );

        target_crop_x = gridSizePositions.target_crop_x;
        target_crop_y = gridSizePositions.target_crop_y;
        target_crop_width = gridSizePositions.target_crop_width;
        target_crop_height = gridSizePositions.target_crop_height;
      }
    }

    let source_crop_x, source_crop_y, source_crop_width, source_crop_height;

    const isSpeakerLayout = this.clipLayout === 'SPEAKER' || this.clipLayout === 'GRID';

    const applyNewCropping =
      isSpeakerLayout &&
      !!optionsForNewSpeakerMapping &&
      Boolean(optionsForNewSpeakerMapping.allPositions.crop_position_face);

    if (applyNewCropping) {
      const {
        crop_x,
        crop_y,
        crop_width,
        crop_height,
        new_target_crop_x,
        new_target_crop_y,
        new_target_crop_width,
        new_target_crop_height
      } = this.getSourceCropValuesForSpeakerLayouts(
        target_crop_x,
        target_crop_y,
        target_crop_width,
        target_crop_height,
        optionsForNewSpeakerMapping,
        adjustedBoxBoundary
      );

      source_crop_x = crop_x;
      source_crop_y = crop_y;
      source_crop_width = crop_width;
      source_crop_height = crop_height;
      target_crop_x = new_target_crop_x;
      target_crop_y = new_target_crop_y;
      target_crop_width = new_target_crop_width;
      target_crop_height = new_target_crop_height;
    } else {
      const { crop_x, crop_y, crop_width, crop_height } = getCentreCropValues(
        videoWidth,
        videoHeight,
        target_crop_width,
        target_crop_height
      );

      source_crop_x = crop_x + source_crop_x_offset;
      source_crop_y = crop_y + source_crop_y_offset;
      source_crop_width = crop_width;
      source_crop_height = crop_height;
    }

    return {
      applyNewCropping,
      source_crop_x: Math.round(source_crop_x),
      source_crop_y: Math.round(source_crop_y),
      source_crop_width: Math.round(source_crop_width),
      source_crop_height: Math.round(source_crop_height),
      target_crop_x: Math.round(target_crop_x),
      target_crop_y: Math.round(target_crop_y),
      target_crop_width: Math.round(target_crop_width),
      target_crop_height: Math.round(target_crop_height)
    };
  }

  drawVideo(canvasContext: CanvasRenderingContext2D, video: HTMLVideoElement, drawVideoOptions: DrawVideoOptions) {
    const cropPositions = this.getCropPositions(drawVideoOptions);
    const {
      source_crop_x,
      source_crop_y,
      source_crop_width,
      source_crop_height,
      target_crop_x,
      target_crop_y,
      target_crop_width,
      target_crop_height
    } = cropPositions;

    canvasContext.drawImage(
      video,
      source_crop_x,
      source_crop_y,
      source_crop_width,
      source_crop_height,
      target_crop_x,
      target_crop_y,
      target_crop_width,
      target_crop_height
    );
    canvasContext.restore();
  }

  drawSpeakerNameTitlePill(
    canvasContext: CanvasRenderingContext2D,
    {
      name,
      title,
      backgroundColor,
      textColor,
      height,
      currentVideoNumber,
      totalNumber,
      crop_x = 0,
      crop_y = 0,
      fontLocation
    }
  ) {
    // Return and do not draw in case none of the fields are available
    if (!name && !title) return;

    const fontName = getCurrentClipFontName(this.clipId);
    const { nameSize, titleSize } = this.getSpeakerNameTitlePillTextSizes(totalNumber);

    const {
      newTitle,
      newName,
      width: canvasPillWidth,
      isTruncated
    } = this.getCanvasTextSizes(totalNumber, name, title, fontName);

    let { pillWidth, pillHeight, pillX } = this.getPillBoxSizes(
      currentVideoNumber,
      totalNumber,
      canvasPillWidth,
      isTruncated
    );

    // crop_x and crop_y gives the value with and without captions
    // and at different grid positions
    const x = crop_x + pillX;
    const y = crop_y + height - pillX - pillHeight;
    const borderRadius = pillHeight / 2;

    // Draw the pillbox
    canvasContext.beginPath();
    canvasContext.moveTo(x, y + pillHeight / 2);
    canvasContext.arcTo(x, y, x + pillWidth, y, borderRadius);
    canvasContext.arcTo(x + pillWidth, y, x + pillWidth, y + pillHeight, borderRadius);
    canvasContext.arcTo(x + pillWidth, y + pillHeight, x, y + pillHeight, borderRadius);
    canvasContext.arcTo(x, y + pillHeight, x, y, borderRadius);
    // Fill style
    canvasContext.fillStyle = backgroundColor;
    // Fill the pill box
    canvasContext.fill();
    canvasContext.closePath();

    const startX = x + borderRadius - 10;

    const maxWidth = pillWidth - borderRadius * 2;

    const nameY = pillHeight / 2 - nameSize / 8;
    const titleY = (nameY * 3) / 2 + nameSize / 2 - titleSize / 2;

    canvasContext.fillStyle = textColor;
    const fontItem = getFontByLocation(fontLocation);
    function drawContent() {
      canvasContext.font = `${nameSize}px ${fontName || 'Inter'}`;
      canvasContext.fillText(newName, startX, y + nameY, maxWidth);

      canvasContext.globalAlpha = 0.6;
      canvasContext.fillStyle = textColor;

      canvasContext.font = `${titleSize}px ${fontName || 'Inter'}`;
      canvasContext.fillText(newTitle, startX, y + titleY, maxWidth);

      // This reset is necessary to avoid affecting other drawings
      canvasContext.globalAlpha = 1;
    }
    if (fontItem) {
      loadFont(fontItem.url, fontItem.name).then(() => {
        drawContent();
      });
    } else {
      drawContent();
    }
  }

  abstract drawAudiogramSpeakerLabel(
    canvasContext: CanvasRenderingContext2D,
    speaker: SpeakerWithDetails,
    textColor: string
  ): void;

  drawAudiogramImage(canvasContext: CanvasRenderingContext2D, image: HTMLImageElement) {
    const { naturalHeight, naturalWidth } = image;
    const targetWidth = this.getWidth() * this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO.width;
    const targetHeight = this.getHeight() * this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO.height;

    const { crop_x, crop_y, crop_width, crop_height } = getCentreCropValues(
      naturalWidth,
      naturalHeight,
      targetWidth,
      targetHeight
    );

    // Needed for capturing thumbnail
    image.crossOrigin = 'anonymous';

    canvasContext.drawImage(image, crop_x, crop_y, crop_width, crop_height, 0, 0, targetWidth, targetHeight);
  }

  abstract getCaptionAttributes({
    containerHeight,
    containerWidth,
    captionFontSize
  }: {
    containerHeight: number;
    containerWidth: number;
    captionFontSize: number;
  }): CaptionAttributes;

  getCaptionsLeftMargin(): number {
    return this.CAPTIONS_LEFT_MARGIN;
  }

  getCaptionsRightMargin(): number {
    return this.CAPTIONS_RIGHT_MARGIN;
  }

  getCaptionsBottomMargin(): number {
    return this.CAPTIONS_BOTTOM_MARGIN;
  }

  abstract getCaptionsFontSize(): number;

  getBorderVideoRadius(): number {
    return this.BORDER_VIDEO_RADIUS;
  }

  drawText(
    canvasContext: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    font: string,
    fillStyle: string,
    opacity: number = 1
  ) {
    canvasContext.fillStyle = fillStyle;
    canvasContext.font = font;
    canvasContext.globalAlpha = opacity;
    canvasContext.fillText(text, x, y, maxWidth);

    canvasContext.globalAlpha = 1;
  }
}
