import {
  formVideoWithAccumulatedWords,
  getCommonBits,
  getDeletedTimeInRange,
  getIntroVideo,
  getOutroVideo,
  getSpeakerPillNameTitleConfig
} from './common';
import { isCustomUpload } from '../clipContentUtil';
import { roundToNDecimalPlaces } from '../utils';
import { SharedAPIStore } from '@/stores/sharedAPI/sharedAPI';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { getVideoManifestUrl } from '@/stores/core';
import { getCentreCropValues } from '@/Pages/Clip/CanvasPlayer/CanvasPlayerUtils';
import { AssetConfig, Clip } from '@/domains/asset';

export function getSpeakerGridLayoutCanvasData({
  clip,
  sharedAPIStoreForClip
}: {
  clip: Clip;
  sharedAPIStoreForClip: SharedAPIStore;
}) {
  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  const {
    introVideoSource,
    introVideoDuration,
    outroVideoSource,
    outroVideoDuration,
    modifiedExcludes,
    showCaption,
    captions,
    canvas_height,
    canvas_width
  } = getCommonBits(sizeConfig, clip, sharedAPIStoreForClip);

  const background_image = clip.asset_metadata.magicLayout?.backgroundImage;

  const { end: introEnd, introVideo } = getIntroVideo(
    introVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    introVideoSource
  );

  const newVideos: AssetConfig['videos'] = [];

  const shapes: any[] = [];
  const text: any[] = [];

  let canvasVideosShapesText: {
    newVideos: AssetConfig['videos'];
    shapes: any[];
    text: any[];
  } = {
    newVideos: [],
    shapes: [],
    text: []
  };

  if (isCustomUpload()) {
    canvasVideosShapesText = getSpeakerGridLayoutCanvasDataForUpload(
      sizeConfig,
      introEnd,
      modifiedExcludes,
      showCaption,
      clip,
      sharedAPIStoreForClip,
      canvas_width,
      canvas_height
    );
  } else {
    canvasVideosShapesText = getSpeakerGridLayoutCanvasDataForRecording(
      sizeConfig,
      introEnd,
      modifiedExcludes,
      showCaption,
      clip,
      sharedAPIStoreForClip
    );
  }

  if (canvasVideosShapesText.newVideos.length) {
    newVideos.push(...canvasVideosShapesText.newVideos);
  }

  if (canvasVideosShapesText.shapes.length) {
    shapes.push(...canvasVideosShapesText.shapes);
  }

  if (canvasVideosShapesText.text.length) {
    text.push(...canvasVideosShapesText.text);
  }

  const { end: outroEnd, outroVideo } = getOutroVideo(
    newVideos[newVideos.length - 1].end,
    outroVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    outroVideoSource
  );

  return {
    canvas_width,
    canvas_height: canvas_height,
    duration: outroEnd,
    background_color: clip.asset_metadata.magicLayout?.backgroundColor || '#000000',
    ...(background_image && { background_image }),
    filename: clip.title,
    videos: [...introVideo, ...newVideos, ...outroVideo],
    captions,
    shapes,
    text
  };
}

function getSpeakerGridLayoutCanvasDataForUpload(
  sizeConfig: ReturnType<typeof getSizeConfig>,
  introVideoDuration: number,
  modifiedExcludes: {
    start: number;
    end: number;
  }[],
  showCaption: boolean,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore,
  canvas_width: number,
  canvas_height: number
) {
  const speakersAnalysis = speakersAnalysisStore.getSnapshot();
  const speakersAnalysisForClip = speakersAnalysis[clip.id];

  const newVideos: AssetConfig['videos'] = [];
  const shapes: any[] = [];
  const text: any[] = [];

  if (speakersAnalysisForClip.time_analysis.length) {
    const transcriptStore = sharedAPIStoreForClip.transcriptStore;
    const mainPlayerRef = sharedAPIStoreForClip.mainPlayerRef;

    const showSpeakerLabels = clip.asset_metadata.magicLayout?.showSpeakerLabels;

    let start = introVideoDuration;
    let end = 0;

    const cumulativeDeletedTimes = speakersAnalysisForClip.times.map((_, index) => {
      return getDeletedTimeInRange(
        // This is always start of the clip to get a cumulative sum
        clip.asset_metadata.start,
        index === speakersAnalysisForClip.times.length - 1
          ? clip.asset_metadata.end
          : speakersAnalysisForClip.times[index + 1],
        modifiedExcludes
      );
    });

    // run through time analysis to get the face positions at the said times
    speakersAnalysisForClip.time_analysis.forEach((analysisPoint, index) => {
      const visible_face_ids = speakersAnalysisForClip.visible_face_ids;

      // filter on faces that are visible
      const visibleFacePositions = analysisPoint.face_positions.filter(face_position =>
        visible_face_ids.includes(face_position.face_id)
      );

      end = roundToNDecimalPlaces(
        introVideoDuration +
          (index === speakersAnalysisForClip.times.length - 1
            ? clip.asset_metadata.end
            : speakersAnalysisForClip.times[index + 1]) -
          clip.asset_metadata.start -
          cumulativeDeletedTimes[index],
        4
      );

      if (visibleFacePositions.length === 0) {
        const {
          source_crop_x,
          source_crop_y,
          source_crop_width,
          source_crop_height,
          target_crop_x,
          target_crop_y,
          target_crop_width,
          target_crop_height
        } = sizeConfig.getCropPositions({
          videoWidth: sharedAPIStoreForClip.mainPlayerRef?.videoWidth || canvas_width,
          videoHeight: sharedAPIStoreForClip.mainPlayerRef?.videoHeight || canvas_height,
          showCaption,
          shouldUseDefaultLayoutValues: true
        });

        newVideos.push({
          start,
          end,
          x: target_crop_x,
          y: target_crop_y,
          z: 0,
          bounding_width: target_crop_width,
          bounding_height: target_crop_height,
          radius: sizeConfig.getBorderVideoRadius(),
          meta: {
            excludes: modifiedExcludes,
            crop_x: source_crop_x,
            crop_y: source_crop_y,
            crop_width: source_crop_width,
            crop_height: source_crop_height,
            source: getVideoManifestUrl(),
            in_time: speakersAnalysisForClip.times[index]
          }
        });
        start = end;
        return;
      }

      // for each visible face, add a video
      visibleFacePositions.forEach((facePosition, facePositionIndex) => {
        const cropPosition = facePosition.crop_position || facePosition.crop_position_face;

        if (!cropPosition) {
          return;
        }

        // Cropping logic starts here
        const {
          applyNewCropping,
          source_crop_x,
          source_crop_y,
          source_crop_width,
          source_crop_height,
          target_crop_x,
          target_crop_y,
          target_crop_width,
          target_crop_height
        } = sizeConfig.getCropPositions({
          videoWidth: mainPlayerRef?.videoWidth!,
          videoHeight: mainPlayerRef?.videoHeight!,
          showCaption,
          totalVideos: visibleFacePositions.length,
          currentVideoNumber: facePositionIndex + 1,
          optionsForNewSpeakerMapping: {
            originalVideoWidth: mainPlayerRef!.videoWidth!,
            originalVideoHeight: mainPlayerRef!.videoHeight!,
            allPositions: facePosition
          },
          adjustedBoxBoundary: speakersAnalysisForClip.adjustedBoxBoundaries?.[index][facePosition.face_id]
        });

        const { crop_x, crop_y, crop_height, crop_width } = getCentreCropValues(
          mainPlayerRef!.videoWidth * (cropPosition.bottom_right.x - cropPosition.top_left.x),
          mainPlayerRef!.videoHeight * (cropPosition.bottom_right.y - cropPosition.top_left.y),
          target_crop_width,
          target_crop_height
        );
        // Cropping logic ends here

        newVideos.push({
          start,
          end,
          x: target_crop_x,
          y: target_crop_y,
          z: 0,
          bounding_width: target_crop_width,
          bounding_height: target_crop_height,
          radius: sizeConfig.getBorderVideoRadius(),
          meta: {
            excludes: modifiedExcludes.map(exclude => ({
              start: exclude.start,
              end: exclude.end
            })),
            crop_x: Math.round(
              applyNewCropping ? source_crop_x : cropPosition.top_left.x * mainPlayerRef!.videoWidth + crop_x
            ),
            crop_y: Math.round(
              applyNewCropping ? source_crop_y : cropPosition.top_left.y * mainPlayerRef!.videoHeight + crop_y
            ),
            crop_width: Math.round(applyNewCropping ? source_crop_width : crop_width),
            crop_height: Math.round(applyNewCropping ? source_crop_height : crop_height),
            source: getVideoManifestUrl(),
            in_time: speakersAnalysisForClip.times[index]
          }
        });

        if (showSpeakerLabels) {
          const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker =>
            speakersAnalysisForClip.speaker_mapping[speaker.id]?.includes(facePosition.face_id)
          );

          if (!speaker) return;

          const speakerPillNameTitleConfig = getSpeakerPillNameTitleConfig(
            { ...speaker },
            true,
            start,
            end,
            visibleFacePositions.length,
            facePositionIndex + 1,
            clip,
            sharedAPIStoreForClip
          );

          if (speakerPillNameTitleConfig?.shapes.length) {
            shapes.push(...speakerPillNameTitleConfig.shapes);
          }

          if (speakerPillNameTitleConfig?.text.length) {
            text.push(...speakerPillNameTitleConfig.text);
          }
        }
      });
      start = end;
    });
  }

  return {
    newVideos,
    shapes,
    text
  };
}

function getSpeakerGridLayoutCanvasDataForRecording(
  sizeConfig: ReturnType<typeof getSizeConfig>,
  introVideoDuration: number,
  modifiedExcludes: {
    start: number;
    end: number;
  }[],
  showCaption: boolean,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore
) {
  const speakerSegmentStore = sharedAPIStoreForClip.speakerSegmentStore as SpeakerSegmentContextType;

  const newVideos: AssetConfig['videos'] = [];
  const shapes: any[] = [];
  const text: any[] = [];

  if (speakerSegmentStore.captions.length) {
    let currentSpeaker = '';
    const accumulatedWords: any[] = [];
    let start = introVideoDuration;

    let end = 0;

    const showSpeakerLabels = clip.asset_metadata.magicLayout?.showSpeakerLabels;

    // {} is used for the last processing
    [...speakerSegmentStore.captions, {} as any]?.forEach(word => {
      if (word.speaker_label !== currentSpeaker) {
        if (accumulatedWords.length > 0) {
          end = roundToNDecimalPlaces(
            introVideoDuration +
              (word?.processed_end_time ?? accumulatedWords[accumulatedWords.length - 1].processed_end_time),
            3
          );

          const allSpeakers = clip.asset_metadata.visible_speakers || [];

          const totalVideos = allSpeakers.length;

          allSpeakers.forEach((speaker, index) => {
            const currentVideoNumber = index + 1;
            const video = formVideoWithAccumulatedWords(
              start,
              end,
              accumulatedWords,
              sizeConfig,
              modifiedExcludes,
              speaker.video,
              showCaption,
              speaker.key,
              clip,
              sharedAPIStoreForClip,
              totalVideos,
              currentVideoNumber
            );
            newVideos.push(video);

            if (showSpeakerLabels) {
              const speakerPillNameTitleConfig = getSpeakerPillNameTitleConfig(
                { ...speaker },
                false,
                start,
                end,
                totalVideos,
                currentVideoNumber,
                clip,
                sharedAPIStoreForClip
              );

              if (speakerPillNameTitleConfig?.shapes.length) {
                shapes.push(...speakerPillNameTitleConfig.shapes);
              }

              if (speakerPillNameTitleConfig?.text.length) {
                text.push(...speakerPillNameTitleConfig.text);
              }
            }
          });

          accumulatedWords.splice(0, accumulatedWords.length);
          start = end;
        }
        currentSpeaker = word.speaker_label;
      }
      accumulatedWords.push(word);
    });
  }

  return {
    newVideos,
    shapes,
    text
  };
}
