import { memo, useCallback, useState } from 'react';
import { assetsMediaContentTranscriptDownloadRetrieve } from '@goldcast/api/content';
import IconButton from '@/components/atoms/Button/IconButton';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { downloadURI } from '@/libs/core';
import { useAppContext } from '@/context/AppContext/AppContext';

const TranscriptDownloadButton = ({ mediaContentId }: { mediaContentId: string }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { logger } = useAppContext();

  const onDownloadButtonClick = useCallback(() => {
    setIsDownloading(true);
    assetsMediaContentTranscriptDownloadRetrieve({
      id: mediaContentId
    })
      .then(response => {
        if (!response.url) {
          throw new Error('Transcript download URL not found');
        }

        downloadURI(response.url);
        showSuccessToast('Transcript download started!');
      })
      .catch(error => {
        logger.error(error);
        showErrorToast('Failed to download transcript');
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [mediaContentId]);

  return (
    <IconButton
      icon={isDownloading ? 'IconLoader2' : 'IconDownload'}
      onClick={onDownloadButtonClick}
      buttonClassName="h-8"
      iconClassName={isDownloading ? 'animate-spin' : ''}
      disabled={isDownloading}
      trackingId="transcript-download-button"
      title="Download Transcript"
      size="base"
    />
  );
};

export default memo(TranscriptDownloadButton);
