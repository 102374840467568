import React, { SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  defaultState,
  formTranscriptContextData,
  getNewCacheSuffix,
  getUsedSpeakers,
  initTranscript,
  setNewCacheSuffix
} from './TranscriptContextUtils';
import { SpeakerWithDetails, TranscriptContextData, TranscriptContextType } from './TranscriptContextTypes';
import { useAppContext } from '../AppContext/AppContext';
import { useClipsContext } from '../ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { updateSharedAPIWithId, updateSpeakerImageMap } from '@/stores/sharedAPI/sharedAPI';
import { currentClip, saveClipChanges } from '@/stores/clip';
import { Error } from '@/domains/global';

export const TranscriptContext = createContext<TranscriptContextType>(defaultState);

export function TranscriptContextProvider({
  children,
  clip_start_time,
  clip_end_time,
  eventId,
  broadcastId,
  onErrorCallback,
  onLoadCompleted
}: {
  children: React.ReactNode;
  clip_start_time?: number;
  clip_end_time?: number;
  eventId?: string;
  broadcastId?: string;
  onErrorCallback?: (error: Error) => void;
  onLoadCompleted?: () => void;
}) {
  const [transcriptContextData, setTranscriptContextData] = useState<TranscriptContextData>(defaultState);
  const [trimming, setTrimming] = useState(false);
  const { logger } = useAppContext();
  const { clipId } = useClipsContext();
  const [isTranscriptInitialized, setIsTranscriptInitialized] = useState(false);

  const loadTranscript = () => {
    setTranscriptContextData(data => ({ ...data }));

    /**
     * This ensures that fresh transcript is loaded when speaker merge or reset has been performed or trimming is being done.
     * In all other cases, the cacheSuffix is not updated and transcript is loaded from disk cache.
     */
    const cacheSuffix = getNewCacheSuffix();

    initTranscript(cacheSuffix, eventId!, broadcastId!, clip_start_time, clip_end_time)
      .then(data => {
        setTranscriptContextData(formTranscriptContextData(...data, clipId));
      })
      .catch(err => {
        logger.error(err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      })
      .finally(() => {
        setTranscriptContextData(data => ({ ...data }));
        setIsTranscriptInitialized(true);
        onLoadCompleted?.();
      });
  };

  const updateSpeakers = ({
    updatedSpeakers,
    shouldUpdateClip = true,
    speakerKey,
    speakerImage
  }: {
    updatedSpeakers: Record<string, SpeakerWithDetails>;
    shouldUpdateClip?: boolean;
    speakerKey?: string;
    speakerImage?: HTMLImageElement;
  }) => {
    setTranscriptContextData((data: SetStateAction<TranscriptContextData>) => {
      return {
        ...data,
        speakersWithDetails: updatedSpeakers,
        usedSpeakers: getUsedSpeakers(updatedSpeakers)
      } as TranscriptContextType;
    });

    if (shouldUpdateClip && clipId && (clip_start_time || clip_end_time)) {
      if (speakerKey && speakerImage) {
        updateSpeakerImageMap(clipId, speakerKey, speakerImage);
      }

      // Required to update the config with the updated speaker information
      saveClipChanges(clipId, true, false, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
    }
  };

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.SpeakersChanged, updateSpeakers);
    return () => {
      EventBus.off(CustomEvents.SpeakersChanged, eventListener);
    };
  }, []);

  useEffect(() => {
    // TODO: @AshwinBhatkal - This logic can be removed on trimming and transcript not loaded over here
    if (trimming) {
      setNewCacheSuffix(Math.random().toString());
    }

    loadTranscript();
  }, [trimming, clip_start_time, clip_end_time]);

  useEffect(() => {
    updateSharedAPIWithId(clipId, { transcriptStore: transcriptContextData });
  }, [transcriptContextData]);

  const transcriptContextValue = useMemo(() => {
    return {
      ...transcriptContextData,
      isTranscriptInitialized,
      trimming,
      setTrimming
    };
  }, [transcriptContextData, trimming, isTranscriptInitialized]);

  return <TranscriptContext.Provider value={transcriptContextValue}>{children}</TranscriptContext.Provider>;
}

export function useTranscriptContext() {
  return useContext(TranscriptContext);
}
