import { memo, useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { TranscriptSelection } from './types';
import IconButton from '@/components/atoms/Button/IconButton';
import { classnames } from '@/libs/utils';
import { clipCaptionsLoader, currentClip, deleteWords, updateClipCaptionsLoadingState } from '@/stores/clip';

function EditTranscriptMenu({
  editPopupWrapperRef,
  editPopupRef,
  isCreatingClip,
  onCreate,
  videoAssetId,
  onDelete,
  transcriptSelection
}: {
  editPopupWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  editPopupRef: React.MutableRefObject<HTMLDivElement | null>;
  isCreatingClip?: boolean;
  onCreate: () => void;
  videoAssetId: string;
  onDelete: () => void;
  transcriptSelection?: TranscriptSelection | null;
}) {
  const clipCaptionsLoaderState = useSyncExternalStore(clipCaptionsLoader.subscribe, clipCaptionsLoader.getSnapshot);
  const [isDeletingWords, setIsDeletingWords] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isDeletingWords && !clipCaptionsLoaderState[videoAssetId]) {
      onDelete();
      setIsDeletingWords(false);
    }
  }, [isDeletingWords, clipCaptionsLoaderState[videoAssetId]]);

  const deleteWordsFromTranscript = useCallback(() => {
    const clipData = currentClip.getSnapshot()[videoAssetId];
    const selectedWords = transcriptSelection?.words;
    if (!clipData || !selectedWords?.length) return;
    const nextStartTime = transcriptSelection?.words[transcriptSelection.words.length - 1].start_time;
    if (!nextStartTime || isNaN(nextStartTime)) return;
    updateClipCaptionsLoadingState(videoAssetId, true);
    deleteWords(clipData.id, {
      prev_end_time: clipData!.asset_metadata.captions?.[selectedWords[0].index - 1]?.end_time,
      next_start_time: nextStartTime,
      bounds: [selectedWords[0].start_time, selectedWords[selectedWords.length - 1].end_time]
    });
    setIsDeletingWords(true);
  }, [transcriptSelection?.words, videoAssetId]);

  return (
    <div
      ref={editPopupWrapperRef}
      className={classnames('invisible absolute left-[33rem] top-0 z-[2000] flex h-full w-12 items-center')}
    >
      <div className="absolute z-30">
        <div className="top-0 flex h-full w-12 shrink-0 items-center justify-center">
          <div className="flex flex-col rounded-xl border bg-white p-1.5 shadow" ref={editPopupRef}>
            <IconButton
              icon={isCreatingClip ? 'IconLoader2' : 'IconMovie'}
              trackingId="icon-create-clip"
              onClick={onCreate}
              variation="text"
              title="Create clip"
              iconClassName={isCreatingClip ? 'animate-spin' : ''}
              buttonClassName="w-10 h-10 z-[20000]"
            />
            <IconButton
              icon={clipCaptionsLoaderState[videoAssetId] ? 'IconLoader2' : 'IconTrash'}
              trackingId="icon-delete-text"
              onClick={deleteWordsFromTranscript}
              variation="text"
              title="Delete"
              iconClassName={clipCaptionsLoaderState[videoAssetId] ? 'animate-spin' : ''}
              buttonClassName="w-10 h-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(EditTranscriptMenu);
