import React, { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSelectionHighlightSpecs,
  getSelectionDuration,
  getWordIdFromElement,
  getParagraphIndexByTime,
  parseTranscriptToParagraphs
} from './utils';
import SpeakerRow from './SpeakerRow';
import TranscriptWord from './TranscriptWord';
import { HighlightedSearchParagraph, SearchParagraphWord, TranscriptParagraph, TranscriptSelection } from './types';
import TranscriptSkeleton from './TranscriptSkeleton';
import { CLIP_MIN_LENGTH, VIRTUAL_CONTAINER_EXTEND_BY_SIZE, VIRTUAL_VIEWPORT_EXTENDED_BY } from './constants';
import useTranscriptHighlightHook from './useTranscriptHighlightHook';
import TranscriptSearch from './TranscriptSearch';
import TranscriptDownloadButton from './TranscriptDownloadButton';
import EditTranscriptMenu from './EditTranscriptMenu';
import CreateClipPopup from './CreateClipPopup';
import useFullClipTranscriptHighlight from './useFullClipTranscriptHighlight';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';
import { classnames, preventDefault } from '@/libs/utils';
import { ClipPlayer, play, updatePlayerTime } from '@/stores/player';
import { Word, WordType } from '@/domains/transcript';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';
import { currentClip } from '@/stores/clip';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { ClipPlayerStore } from '@/stores/playerV2';

export default function FullTranscript({
  onCreateClip,
  videoAssetId,
  playerStore,
  onTimeUpdate
}: {
  onCreateClip: (words: Word[]) => Promise<void>;
  videoAssetId?: string;
  playerStore: ClipPlayer | ClipPlayerStore;
  onTimeUpdate?: (time: number) => void;
}) {
  const navigate = useNavigate();
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();

  const virtuoso = useRef<VirtuosoHandle>(null);
  const container = useRef<HTMLDivElement>(null);
  const createClipWrapper = useRef<HTMLDivElement>(null);
  const createClipPopup = useRef<HTMLDivElement>(null);
  const [transcriptSelection, setTranscriptSelection] = useState<TranscriptSelection | null>();
  const [anchorNode, setAnchorNode] = useState<Node | null>(null);
  const [anchorNodeCandidates, setAnchorNodeCandidates] = useState<Node[]>([]);
  const [focusNode, setFocusNode] = useState<Node | null>(null);
  const [highlightedParagraph, setHighlightedParagraph] = useState<HighlightedSearchParagraph | null>(null);
  const transcriptStore = useTranscriptContext() as TranscriptContextType;
  const [isCreatingClip, setIsCreatingClip] = useState<boolean | undefined>(undefined);
  const transcript = useMemo(() => transcriptStore.transcript, [transcriptStore.transcript]);
  const { isScrolled, handleOnScroll } = useElementScrolledHook();
  const editPopupRef = useRef<HTMLDivElement>(null);
  const editPopupWrapperRef = useRef<HTMLDivElement>(null);
  const allClips = useSyncExternalStore(currentClip.subscribe, currentClip.getSnapshot);

  const isEdit = useMemo(() => {
    return !!videoAssetId;
  }, [videoAssetId]);

  const videoAssetClip = useMemo(() => {
    return !!videoAssetId ? allClips[videoAssetId] : undefined;
  }, [allClips, videoAssetId]);

  const paragraphs: TranscriptParagraph[] = useMemo(() => {
    return parseTranscriptToParagraphs(
      transcript,
      transcriptStore?.speakersWithDetails,
      videoAssetClip?.asset_metadata.deletes
    );
  }, [transcript, transcriptStore?.speakersWithDetails, videoAssetClip]);

  const { highlightActiveWord } = useTranscriptHighlightHook();
  const { highlightFullClipActiveWord } = useFullClipTranscriptHighlight();

  const speakerSegmentContextData = useSpeakerSegmentContext();

  useEffect(() => {
    isEdit ? highlightFullClipActiveWord(container, playerStore) : highlightActiveWord(container, playerStore);
  }, [
    playerStore.currentTime,
    playerStore.currentSrtIndex,
    playerStore.duration,
    playerStore.paused,
    highlightActiveWord,
    isEdit,
    highlightFullClipActiveWord,
    playerStore
  ]);

  const updateTime = useCallback(
    (time: number) => {
      videoAssetId ? onTimeUpdate?.(time) : updatePlayerTime(time);
    },
    [onTimeUpdate, videoAssetId]
  );
  /**
   * playFromWord is different from playFromTime as it does not consider if player is paused or not
   */
  const playFromWord = useCallback(
    (wordStartTime: number) => {
      updateTime(wordStartTime);
      play(wordStartTime);
    },
    [updateTime]
  );

  const scrollToVirtuosoIndex = useCallback(
    ({ index, words }: { index: number; words: Word[] | SearchParagraphWord[] }) => {
      // Scroll to the paragraph (needed because this is a virtualized list)
      virtuoso?.current?.scrollToIndex({
        index,
        align: 'center',
        behavior: 'auto'
      });

      requestAnimationFrame(() => {
        // Scroll to the first searched word
        const paragraph = paragraphs[index];
        const firstSearchedWord = words[0];
        const firstSearchedWordIndex = paragraph.words?.find(w => w.content === firstSearchedWord.content)?.index;

        if (!firstSearchedWordIndex || firstSearchedWordIndex === -1) return;

        const firstSearchedWordElement = document.querySelector(`[data-word-id="${firstSearchedWordIndex}"]`);

        if (!firstSearchedWordElement) return;

        firstSearchedWordElement.scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });
      });
    },
    [paragraphs]
  );

  const onTimelineChanged = useCallback(
    ({ time }: { time: number }) => {
      const closestStart = transcript.reduce((prev, { start_time: curr, type }) => {
        return Math.abs(curr - time) < Math.abs(prev - time) && type !== WordType.Punctuation ? curr : prev;
      }, 0);
      const paragraphIndex = getParagraphIndexByTime(closestStart, paragraphs);
      if (paragraphIndex >= 0) {
        scrollToVirtuosoIndex({ index: paragraphIndex, words: paragraphs[paragraphIndex].words });
        updateTime(time);
      }
    },
    [paragraphs, transcript, scrollToVirtuosoIndex, updateTime]
  );

  const cleanUpSelection = useCallback(() => {
    createClipPopup.current?.classList.add('invisible');
    editPopupWrapperRef.current?.classList.add('invisible');
    document.getSelection()?.removeAllRanges();
    setTranscriptSelection(null);
    setAnchorNode(null);
    setAnchorNodeCandidates([]);
    setFocusNode(null);
  }, []);

  const showCreateClipPopup = useCallback(
    (selectionWords: Word[], left: number, top: number) => {
      if (!isEdit && selectionWords.length < CLIP_MIN_LENGTH) {
        cleanUpSelection();
        return;
      }

      setTranscriptSelection({
        words: selectionWords,
        duration: getSelectionDuration(selectionWords)
      });

      const element = createClipPopup.current;
      if (element) {
        element.classList.remove('invisible');
        element.style.left = `${left}px`;
        element.style.top = `${top}px`;
      }
      editPopupWrapperRef.current?.classList.remove('invisible');
    },
    [cleanUpSelection]
  );

  const setFocusNodeFromSelection = useCallback(() => {
    const selection = document.getSelection();

    if (!selection?.focusNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value) {
      return;
    }

    if (focusNode) {
      return;
    }

    const anchorNodeIndex = parseInt(selection?.anchorNode?.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const focusNodeIndex = parseInt(selection?.focusNode?.parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNodeIndex === -1) return;

    if (
      selection?.focusOffset &&
      selection?.focusOffset >= (selection?.focusNode?.textContent || '').trim().length &&
      anchorNodeIndex > focusNodeIndex
    ) {
      const nextParentSibling = selection?.focusNode?.parentElement?.nextElementSibling;
      if (nextParentSibling && nextParentSibling.attributes?.getNamedItem('data-word-id')?.value) {
        setFocusNode(nextParentSibling.childNodes[0]);
      } else {
        setFocusNode(selection.focusNode);
      }
    } else {
      setFocusNode(selection.focusNode);
    }
  }, [focusNode]);

  const onPointerUp = useCallback(
    (ev: MouseEvent) => {
      if ((ev.target as HTMLElement)?.dataset?.testId === 'create-clip-button') {
        return;
      }

      if (
        !(createClipWrapper.current?.contains(ev.target as Node) || editPopupRef.current?.contains(ev.target as Node))
      ) {
        setTranscriptSelection(null);
      }

      setFocusNodeFromSelection();
    },
    [setFocusNodeFromSelection]
  );

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        cleanUpSelection();
        return;
      }

      if (ev.shiftKey && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(ev.key)) {
        setFocusNodeFromSelection();
      }
    },
    [cleanUpSelection, setFocusNodeFromSelection]
  );

  const onClipboardCopy = useCallback(
    (event: ClipboardEvent) => {
      if (!anchorNode || !focusNode) return;

      event.preventDefault();

      const wordBounds = getSelectionHighlightSpecs(anchorNode, focusNode);

      if (!wordBounds) return;

      const wordsToSelect = transcript.slice(wordBounds.startIndex, wordBounds.endIndex + 1);
      const textToCopy = wordsToSelect.map(word => word.content).join(' ');
      navigator.clipboard.writeText(textToCopy);
    },
    [anchorNode, focusNode, transcript]
  );

  useEffect(() => {
    if (isCreatingClip === undefined || isCreatingClip) {
      return;
    }

    navigate(`/${eventId}/${broadcastId}/clips`);
  }, [isCreatingClip, eventId, broadcastId]);

  useEffect(() => {
    // Disable scroll when create clip popup is visible
    const virtuosoContainer = document.querySelector(`[data-test-id="virtuoso-scroller"]`);
    if (transcriptSelection) {
      virtuosoContainer?.addEventListener('scroll', preventDefault, false);
      virtuosoContainer?.addEventListener('mousewheel', preventDefault, false);
      virtuosoContainer?.addEventListener('touchmove', preventDefault, false);
      document.addEventListener('copy', onClipboardCopy);
    }
    return () => {
      if (transcriptSelection) {
        virtuosoContainer?.removeEventListener('scroll', preventDefault, false);
        virtuosoContainer?.removeEventListener('mousewheel', preventDefault, false);
        virtuosoContainer?.removeEventListener('touchmove', preventDefault, false);
        document.removeEventListener('copy', onClipboardCopy);
      }
    };
  }, [transcriptSelection]);

  const onClickCreateClipButton = useCallback(() => {
    if (transcriptSelection?.words && transcriptSelection.words.length) {
      setIsCreatingClip(true);
      onCreateClip(transcriptSelection.words).then(() => {
        cleanUpSelection();
        setIsCreatingClip(false);
      });
    }
  }, [transcriptSelection, onCreateClip, cleanUpSelection]);

  const onSelectionChange = useCallback(() => {
    const selection = document.getSelection();

    if (!selection?.anchorNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value) {
      return;
    }

    if (anchorNode) {
      return;
    }

    if (
      selection?.anchorOffset &&
      selection?.anchorOffset >= (selection?.anchorNode?.textContent || '').trim().length
    ) {
      const nextParentSibling = selection?.anchorNode?.parentElement?.nextElementSibling;
      if (
        nextParentSibling &&
        nextParentSibling.attributes?.getNamedItem('data-word-id')?.value &&
        nextParentSibling.childNodes?.[0]
      ) {
        // We're keeping both nodes to later check which one is the correct one based on whether focusNode is before or after them
        setAnchorNodeCandidates([selection?.anchorNode, nextParentSibling.childNodes[0]]);
      } else if (selection?.anchorNode) {
        setAnchorNode(selection?.anchorNode);
      }
    } else if (selection?.anchorNode) {
      setAnchorNode(selection?.anchorNode);
    }
  }, [anchorNode]);

  useEffect(() => {
    if (!anchorNodeCandidates.length || anchorNodeCandidates.length <= 1 || !focusNode || anchorNode) {
      return;
    }

    const focusNodeIndex = parseInt(focusNode?.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNode0Index = parseInt(anchorNodeCandidates[0].parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNode1Index = parseInt(anchorNodeCandidates[1].parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNode0Index === -1 || anchorNode1Index === -1) return;

    if (focusNodeIndex > anchorNode1Index) {
      setAnchorNode(anchorNodeCandidates[1]);
    } else {
      setAnchorNode(anchorNodeCandidates[0]);
    }
  }, [anchorNode, anchorNodeCandidates, focusNode]);

  useEffect(() => {
    if (!anchorNode?.parentElement || !focusNode?.parentElement) {
      return;
    }

    if (anchorNode === focusNode) {
      const wordId = getWordIdFromElement(anchorNode.parentElement);
      if (!isNaN(wordId)) {
        const wordStartTime = transcript[wordId].start_time;
        const segmentWord = speakerSegmentContextData?.captions.find(w => w.start_time === wordStartTime);
        playFromWord(segmentWord?.processed_start_time ?? transcript[wordId].start_time);
      }
      return;
    }

    const wordBounds = getSelectionHighlightSpecs(anchorNode, focusNode);

    if (!wordBounds) {
      return;
    }

    const selection = document.getSelection();
    if (selection) {
      selection.setBaseAndExtent(anchorNode, 0, focusNode, 0);
    }

    const wordsToSelect = transcript.slice(wordBounds.startIndex, wordBounds.endIndex + 1);

    let addendum = 0;
    if (focusNode.compareDocumentPosition(anchorNode) === Node.DOCUMENT_POSITION_PRECEDING) {
      addendum = 20;
    } else {
      addendum = -60; // with height of create clip popup
    }

    const boundingRect = focusNode.parentElement.getBoundingClientRect();
    const x = boundingRect.x;
    const y = boundingRect.y + addendum;

    showCreateClipPopup(wordsToSelect, x, y);
  }, [anchorNode, focusNode, transcript, playFromWord, showCreateClipPopup]);

  const onPointerDown = useCallback(
    (e: MouseEvent) => {
      if (
        !(createClipWrapper.current?.contains(e.target as Node) || editPopupRef.current?.contains(e.target as Node))
      ) {
        cleanUpSelection();
      }
    },
    [cleanUpSelection]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.TimelineChanged, onTimelineChanged);

    document.addEventListener('pointerup', onPointerUp);
    document.addEventListener('selectionchange', onSelectionChange);
    document.addEventListener('pointerdown', onPointerDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      EventBus.off(CustomEvents.TimelineChanged, eventListener);

      document.removeEventListener('pointerup', onPointerUp);
      document.removeEventListener('selectionchange', onSelectionChange);
      document.removeEventListener('pointerdown', onPointerDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    transcript,
    onPointerUp,
    onTimelineChanged,
    onPointerDown,
    onKeyUp,
    setFocusNodeFromSelection,
    onSelectionChange
  ]);

  const handleEditSpeaker = useCallback(speakerId => {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }, []);

  const forceHighlightRange = useMemo(() => {
    if (!focusNode || !anchorNode) return [-1, -1];
    if (focusNode === anchorNode) return [-1, -1];

    const focusNodeIndex = parseInt(focusNode.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNodeIndex = parseInt(anchorNode.parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNodeIndex === -1) return [-1, -1];

    return [focusNodeIndex, anchorNodeIndex];
  }, [focusNode, anchorNode]);

  return (
    <React.Fragment>
      <div className="z-20 flex h-full w-[34rem] py-2 transition-all duration-[400ms] ease-in-out">
        <div
          className="relative w-full overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10"
          ref={container}
          data-testid="transcript-container"
        >
          <div
            className={classnames(
              'sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white px-5 py-4 transition-all duration-150 ease-in-out',
              {
                'shadow-lg shadow-slate-900/[0.075]': isScrolled
              }
            )}
          >
            <span className="select-none text-lg font-medium leading-tight">Transcript</span>
            <div className="flex items-center gap-2">
              <TranscriptSearch
                paragraphs={paragraphs}
                onOccurrencesChange={scrollToVirtuosoIndex}
                setHighlightedParagraph={setHighlightedParagraph}
              />
              {broadcastId && <TranscriptDownloadButton mediaContentId={broadcastId} />}
            </div>
          </div>
          {!paragraphs.length ? (
            <TranscriptSkeleton />
          ) : (
            <>
              <Virtuoso
                overscan={{
                  main: VIRTUAL_CONTAINER_EXTEND_BY_SIZE,
                  reverse: VIRTUAL_CONTAINER_EXTEND_BY_SIZE
                }}
                increaseViewportBy={{
                  bottom: VIRTUAL_VIEWPORT_EXTENDED_BY,
                  top: 0
                }}
                // 93% height because of search
                style={{
                  height: `${paragraphs.length ? '93%' : '0px'}`,
                  overflowX: 'hidden',
                  marginLeft: '1.25rem',
                  marginRight: '0px'
                }}
                className={classnames('leading-[25px] text-slate-700 selection:rounded-sm  selection:text-white')}
                data={paragraphs}
                ref={virtuoso}
                onScroll={handleOnScroll}
                itemContent={(index: number, paragraph: TranscriptParagraph) => {
                  const prevParagraph = paragraphs[index - 1];
                  const nextParagraph = paragraphs[index + 1];

                  if (paragraph.speakerDetails) {
                    return (
                      <SpeakerRow
                        speakerDetails={paragraph.speakerDetails}
                        index={index}
                        wordId={paragraphs[index + 1]?.words[0]?.index}
                        handleEditSpeaker={() => handleEditSpeaker(paragraph.speakerDetails?.speaker?.id)}
                      />
                    );
                  }

                  if (paragraph.words) {
                    return (
                      <div
                        className={classnames('pl-16 pr-4', {
                          '-mt-10': prevParagraph?.speakerDetails,
                          'pb-3': nextParagraph?.words,
                          'pb-8':
                            nextParagraph?.speakerDetails || !(nextParagraph?.words || nextParagraph?.speakerDetails)
                        })}
                      >
                        {prevParagraph?.speakerDetails ? (
                          <div className="mb-2 select-none leading-tight">
                            <button
                              data-testid="transcript-speaker-name-button"
                              className="group flex items-center space-x-1 text-sm text-slate-700"
                              onClick={() => handleEditSpeaker(prevParagraph?.speakerDetails?.speaker?.id)}
                            >
                              <span>{`${prevParagraph?.speakerDetails?.speaker?.first_name} ${prevParagraph?.speakerDetails?.speaker.last_name}`}</span>
                              <IconEdit className="hidden h-4 w-4 group-hover:flex" />
                            </button>
                            <div className="text-xs text-slate-500">
                              {prevParagraph?.speakerDetails?.start} - {prevParagraph?.speakerDetails?.end}
                            </div>
                          </div>
                        ) : null}
                        {paragraph.words?.map((word, i) => {
                          return (
                            <React.Fragment key={word.content + i}>
                              <TranscriptWord
                                word={word}
                                foundWords={highlightedParagraph?.index === index ? highlightedParagraph?.words : []}
                                forceHighlight={forceHighlightRange.includes(word.index)}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }

                  return null;
                }}
              />
            </>
          )}
        </div>
      </div>
      {isEdit ? (
        <EditTranscriptMenu
          editPopupWrapperRef={editPopupWrapperRef}
          editPopupRef={editPopupRef}
          isCreatingClip={isCreatingClip}
          onCreate={onClickCreateClipButton}
          videoAssetId={videoAssetId!}
          transcriptSelection={transcriptSelection}
          onDelete={cleanUpSelection}
        />
      ) : (
        <CreateClipPopup
          createClipPopup={createClipPopup}
          createClipWrapper={createClipWrapper}
          transcriptSelection={transcriptSelection}
          onCreate={onClickCreateClipButton}
          isCreatingClip={isCreatingClip}
        />
      )}
    </React.Fragment>
  );
}
