import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { assetsContentUploadPartialUpdate } from '@goldcast/api/content';
import { core } from '@/stores/core';
import TitlePopup from '@/components/atoms/TitlePopup';

export default function SessionTitlePopup() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const initialTitle = coreStore.content?.title;
  const initialRecordingDate = coreStore.uploadData?.recording_date || '';

  const [title, setTitle] = useState(initialTitle);
  const [recordingDate, setRecordingDate] = useState(initialRecordingDate);

  useEffect(() => {
    if (coreStore.content?.title !== title) {
      setTitle(coreStore.content?.title);
    }
    if (!!coreStore.uploadData?.recording_date && coreStore.uploadData?.recording_date !== recordingDate) {
      setRecordingDate(coreStore.uploadData?.recording_date);
    }
  }, [coreStore.content?.title, coreStore.uploadData?.recording_date]);

  const handleChangeTitle = e => {
    setTitle(e.target.value);
  };

  const handleChangeRecordingDate = e => {
    setRecordingDate(e.target.value);
  };

  const handleRemoteUpdate = useCallback(() => {
    const hasChanged = title !== initialTitle || recordingDate !== initialRecordingDate;

    if (!hasChanged) return;

    if (!coreStore.uploadData) return;

    if (!title?.trim()) {
      setTitle(initialTitle);
    }

    const newUploadData = {
      ...coreStore.uploadData,
      title: title?.trim(),
      recording_date: recordingDate
    };

    assetsContentUploadPartialUpdate({
      id: coreStore.uploadData.id,
      body: newUploadData
    }).then(() => {
      core.update(data => ({
        ...data,
        content: {
          ...data.content,
          title: newUploadData.title
        },
        uploadData: newUploadData
      }));
    });
  }, [coreStore.uploadData, initialTitle, title, initialRecordingDate, recordingDate]);

  return (
    <TitlePopup
      title={title}
      onUpdate={handleRemoteUpdate}
      onTitleChange={handleChangeTitle}
      onRecordingDateChange={handleChangeRecordingDate}
      recordingDate={recordingDate}
    />
  );
}
