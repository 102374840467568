import { useCallback } from 'react';
import { ClipPlayerStore } from '@/stores/playerV2';
import useThrottle from '@/hooks/useThrottle';
import classes from './Transcript.module.scss';

export default function useFullClipTranscriptHighlight() {
  const scrollToWord = (highlightedWordElement, container) => {
    if (highlightedWordElement) {
      const elementRect = highlightedWordElement.getBoundingClientRect();
      const containerRect = container.current?.getBoundingClientRect();
      // focus highlighted word only if it's in the viewport
      if (containerRect && elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom) {
        highlightedWordElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  };

  const throttledScrollToWord = useThrottle(scrollToWord, 500);

  const highlightFullClipActiveWord = useCallback(
    (container: React.RefObject<HTMLDivElement>, playerStore: ClipPlayerStore) => {
      const currSrt = playerStore.currentSrtIndex;
      const currTime = playerStore.currentTime;
      container.current!.querySelector(`.${classes['active-word']}`)?.classList.remove(classes['active-word']);
      if (!container || isNaN(currSrt)) return;

      if (!playerStore.paused) {
        const words = container.current!.querySelectorAll(`.srt-index-${currSrt}`);
        let highlightedWordElement;

        (words || []).forEach(word => {
          const { wordStartTime = '0', wordEndTime = '0' } = (word as HTMLDivElement).dataset;
          if (parseFloat(wordStartTime) <= currTime && parseFloat(wordEndTime) >= currTime) {
            highlightedWordElement = word as unknown as HTMLDivElement;
            (highlightedWordElement as HTMLDivElement)?.classList.add(classes['active-word']);
          }
        });

        // Use throttle because clip currentTime change is triggered every 30ms
        throttledScrollToWord(highlightedWordElement, container);
      }
    },
    [throttledScrollToWord]
  );

  return { highlightFullClipActiveWord };
}
