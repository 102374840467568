import { Transition } from '@headlessui/react';
import { IconTrash } from '@tabler/icons-react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import FontSelector from '../../../SideBar/FontSelector/FontSelector';
import CaptionsMenuTooltip from './CaptionsMenuTooltip';
import CaptionsMenuDropdown from './CaptionsMenuDropdown';
import {
  CAPTION_ANIMATION_STYLE_OPTIONS,
  CAPTION_HIGHLIGHT_OPTIONS,
  CAPTION_SIZE_DEFAULT_OPTION,
  CAPTION_SIZE_OPTIONS
} from './constants';
import CaptionsColorsMenu from './CaptionsColorsMenu';
import { getNextCaptionsPlacement } from '../utils';
import { toggleCaptions, updateCaptionStyles, updateHighlightType } from '@/stores/clip';
import { CaptionsPlacement, CaptionStyle, HighlightType } from '@/domains/asset';
import { classnames } from '@/libs/utils';
import Icon from '@/components/atoms/Icon';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

function CaptionsMenu({
  isVisible,
  parentRef,
  onPlacementChange,
  onSizeChange
}: {
  isVisible: boolean;
  parentRef: HTMLDivElement | null;
  onPlacementChange?: (placement: CaptionsPlacement) => void;
  onSizeChange?: (newScale: number) => void;
}) {
  const { clipId, clipData } = useClipsContext();
  const menuRef = useRef<HTMLDivElement>(null);
  const [offScreenMargin, setOffScreenMargin] = useState(0);
  const [isMenusDirectionUp, seIsMenusDirectionUp] = useState(false);

  const clipMetadata = useMemo(() => {
    return clipData.asset_metadata;
  }, [clipData.asset_metadata]);

  function removeCaptions() {
    toggleCaptions(clipId, false);
  }

  function updateCaptionSize(val: number) {
    updateCaptionStyles(clipId, { scale: val });
    onSizeChange?.(val);
  }

  const isAudiogram = useMemo(() => {
    return clipMetadata.layout === 'AUDIOGRAM';
  }, [clipMetadata.layout]);

  const computeOffScreenWidth = useCallback(() => {
    if (!isVisible || !menuRef.current || isAudiogram) return;
    const menuRect = menuRef.current.getBoundingClientRect();
    const offScreenWidth = menuRect.right + offScreenMargin - window.innerWidth + 50;
    setOffScreenMargin(Math.max(offScreenWidth, 0));
  }, [parentRef, isVisible, isAudiogram]);

  const computeIsMenusDirectionUp = useCallback((e: React.MouseEvent) => {
    seIsMenusDirectionUp(window.innerHeight / 2 < e.clientY);
  }, []);

  const placementIcon = useMemo(() => {
    switch (clipMetadata.caption_styles?.placement) {
      case CaptionsPlacement.Top:
        return 'IconAlignBoxCenterTop';
      case CaptionsPlacement.Bottom:
        return 'IconAlignBoxCenterBottom';
      default:
        return 'IconAlignBoxCenterMiddle';
    }
  }, [clipMetadata.caption_styles?.placement]);

  const updateCaptionHighlight = useCallback(
    (val: HighlightType) => {
      updateHighlightType(clipId, val);
    },
    [clipId]
  );

  const isPortrait = useMemo(() => {
    return clipMetadata.size === 'PORTRAIT';
  }, [clipData]);

  const selectedSize = useMemo(() => {
    return (
      CAPTION_SIZE_OPTIONS.find(o => o.value === clipMetadata.caption_styles?.scale) || CAPTION_SIZE_DEFAULT_OPTION
    );
  }, [clipMetadata.caption_styles?.scale]);

  function toggleCaptionCaps() {
    updateCaptionStyles(clipId, { caps: !isCapsLock });
  }

  function toggleCaptionPlacement() {
    const nextPlacement = getNextCaptionsPlacement(clipMetadata.caption_styles?.placement);
    updateCaptionStyles(
      clipId,
      {
        placement: nextPlacement
      },
      false
    );
    onPlacementChange?.(nextPlacement);
  }

  const updateCaptionStyle = useCallback(
    (val: CaptionStyle) => {
      updateCaptionStyles(clipId, { animation: val });
      if (val === CaptionStyle.Background && clipMetadata.highlight_type === 'box') {
        updateCaptionHighlight('text');
      }
    },
    [clipId, clipMetadata.highlight_type, updateCaptionHighlight]
  );

  const isCapsLock = useMemo(() => {
    return !!clipMetadata.caption_styles?.caps;
  }, [clipMetadata.caption_styles?.caps]);

  const marginBottom = useMemo(() => {
    if (isAudiogram) return 50;
    return (parentRef?.offsetHeight || 0) + 50;
  }, [parentRef?.offsetHeight, menuRef]);

  const highlightOptions = useMemo(() => {
    if (clipMetadata.caption_styles?.animation === CaptionStyle.Background) {
      return CAPTION_HIGHLIGHT_OPTIONS.filter(v => v.value !== 'box');
    }
    return CAPTION_HIGHLIGHT_OPTIONS;
  }, [clipMetadata.caption_styles?.animation]);

  return (
    <Transition
      appear
      as="div"
      show={isVisible}
      className="fixed z-[50] flex cursor-auto items-center justify-center transition-all duration-100"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-96"
      leaveFrom="opacity-100 max-h-96"
      leaveTo="opacity-0 max-h-0"
      beforeEnter={computeOffScreenWidth}
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <div
        id="captions-menu"
        className={classnames('relative flex space-x-1 rounded-lg border bg-white p-0.5 shadow')}
        ref={menuRef}
        style={{ right: `${offScreenMargin}px`, marginTop: `-${marginBottom}px` }}
      >
        {/* Style */}
        <div
          className={classnames(
            'group/button relative cursor-pointer rounded-md text-sm',
            isPortrait ? 'w-28' : 'w-32'
          )}
        >
          <CaptionsMenuDropdown
            onChange={updateCaptionStyle}
            options={CAPTION_ANIMATION_STYLE_OPTIONS}
            selectedValue={clipMetadata.caption_styles?.animation || CaptionStyle.Basic}
            tooltip="Style"
          />
          <div className="flex -translate-y-5 items-center justify-center">
            <CaptionsMenuTooltip content="Style" />
          </div>
        </div>
        {/* Font family */}
        <div
          className={classnames(
            'group/button relative cursor-pointer rounded-md text-sm',
            isPortrait ? 'w-28' : 'w-32'
          )}
        >
          <FontSelector isInline={true} />
          <div className="flex -translate-y-5 items-center justify-center">
            <CaptionsMenuTooltip content="Font" />
          </div>
        </div>
        {/* Font size */}
        <div className={classnames('relative cursor-pointer rounded-md text-sm', isPortrait ? 'w-28' : 'w-32')}>
          <CaptionsMenuDropdown
            onChange={updateCaptionSize}
            options={CAPTION_SIZE_OPTIONS}
            selectedValue={selectedSize.value}
            selectedLabel={selectedSize.label}
            tooltip="Size"
          />
        </div>
        {/* Placement */}
        {!isAudiogram && (
          <button
            className="group/button relative flex w-10 cursor-pointer items-center justify-center rounded-md hover:bg-slate-100"
            onClick={toggleCaptionPlacement}
          >
            <Icon name={placementIcon} size={20} />
            <CaptionsMenuTooltip content="Placement" />
          </button>
        )}
        {/* Caps lock */}
        <button
          className="group/button relative flex w-10 cursor-pointer items-center justify-center rounded-md hover:bg-slate-100"
          onClick={toggleCaptionCaps}
        >
          <Icon name={isCapsLock ? 'IconLetterCaseUpper' : 'IconLetterCase'} size={20} />
          <CaptionsMenuTooltip content="Letter Case" />
        </button>
        {/* Font color */}
        <div className="relative z-10">
          <CaptionsColorsMenu
            clip={clipData}
            isDirectionUp={isMenusDirectionUp}
            onMenuOpened={computeIsMenusDirectionUp}
          />
        </div>
        {/* Highlight */}
        <div className="relative h-10 w-10 rounded-md text-sm">
          <CaptionsMenuDropdown
            onChange={updateCaptionHighlight}
            options={highlightOptions}
            selectedValue={clipMetadata.highlight_type || 'none'}
            icon="IconKeyframes"
            tooltip="Animation"
            position="left"
          />
        </div>
        {/* Remove */}
        {!isAudiogram && (
          <button
            className="group/button relative flex w-10 cursor-pointer items-center justify-center rounded-md hover:bg-slate-100"
            onClick={removeCaptions}
          >
            <IconTrash size={20} />
            <CaptionsMenuTooltip content="Remove" />
          </button>
        )}
      </div>
    </Transition>
  );
}

export default memo(CaptionsMenu);
