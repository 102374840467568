import React, { useCallback } from 'react';
import { getHighligtedWordContent } from './utils';
import { HighlightedSearchWord } from './types';
import { Word, WordType } from '@/domains/transcript';
import classes from './Transcript.module.scss';

export default function TranscriptWord({
  word,
  foundWords,
  forceHighlight = false
}: {
  word: Word;
  foundWords: { [key: number]: HighlightedSearchWord };
  forceHighlight?: boolean;
}) {
  const parseWord = useCallback(
    (word: Word) => {
      let { content } = word;

      if (foundWords[word.start_time]) {
        content = getHighligtedWordContent(foundWords[word.start_time], content, word.index);
      }

      return {
        ...word,
        content: foundWords[word.start_time]?.highlightSpace ? content : content + ' '
      };
    },
    [foundWords]
  );

  const isWordVisible = useCallback((word: Word) => {
    return word.type !== WordType.Punctuation;
  }, []);

  const parsedWord = parseWord({ ...word });
  return (
    isWordVisible(word) && (
      <div
        data-word-id={word.index}
        data-word-start-time={parsedWord.start_time}
        data-word-end-time={parsedWord.end_time}
        className={`${classes.word} word relative z-10 inline-block whitespace-pre selection:bg-deep-orange
        ${'srt-index-' + parsedWord.srt_index} ${forceHighlight ? 'bg-deep-orange text-white' : ''} ${
          word.is_deleted ? 'select-none text-slate-700 line-through opacity-30' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: parsedWord.content }}
      ></div>
    )
  );
}
