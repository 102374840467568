import { TextWidthCalculationProps, TextWidthCalculationResult } from './types';

/**
 * Creates temporary canvas to measureText that can fit inside maxWidth
 * Gets new (potentially truncated) values for name and title and max width of the pill
 * @param props that includes canvas dimesions, name & title font and content, and max pill width
 * @returns new (potentially truncated) name & title values. Also max pill width
 */
export function getCanvasTextDimensions(props: TextWidthCalculationProps): TextWidthCalculationResult {
  const { canvasHeight, canvasWidth, name, nameFont, title, titleFont, maxWidth } = props;
  const tempCanvas = document.createElement('canvas');
  const tempCanvasContext = tempCanvas.getContext('2d');
  if (tempCanvasContext) {
    tempCanvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
    // Get name sizes
    tempCanvasContext.font = nameFont;
    const { width: newNameWidth, newText: newName } = getCanvasMaxWidth(tempCanvasContext, name, maxWidth);
    const nameHeight = getCanvasTextHeight(tempCanvasContext);
    // Get title sizes
    tempCanvasContext.font = titleFont;
    const { width: newTitleWidth, newText: newTitle } = getCanvasMaxWidth(tempCanvasContext, title, maxWidth);
    const titleHeight = getCanvasTextHeight(tempCanvasContext);
    tempCanvas.remove();
    return {
      newTitle,
      newName,
      nameHeight,
      titleHeight,
      width: Math.max(newNameWidth, newTitleWidth),
      isTruncated: newTitle !== title || newName !== name
    };
  }
  tempCanvas.remove();
  return {
    newTitle: title,
    newName: name,
    width: maxWidth,
    isTruncated: false
  };
}

/**
 * Computes max width for the speaker pill in the canvas and text that should fit inside
 * @param tempCanvasContext temporary canvas context used for text measuring
 * @param text to measure (name | title)
 * @param font for text (nameFont | titleFont) in format of size and family - 15px Inter
 * @param maxWidth for the pill
 * @returns pill width and new (potentially truncated text)
 */
function getCanvasMaxWidth(
  tempCanvasContext: CanvasRenderingContext2D,
  text: string,
  maxWidth
): { width: number; newText: string } {
  if (!text) {
    return {
      width: 0,
      newText: ''
    };
  }
  let newText = text;
  let currentWidth; // keep the last value before it goes over maxWidth
  // Iterate over every letter in the text
  for (let i = 0; i < text.length; i++) {
    const currentText = text.substring(0, i);
    let metrics = tempCanvasContext.measureText(currentText);
    if (metrics.width >= maxWidth) {
      newText = currentText.substring(0, currentText.length - 4).trim() + '...';
      break;
    } else {
      currentWidth = metrics.width;
    }
  }

  return {
    width: currentWidth,
    newText
  };
}

/**
 * Returns actual text height that is used for shared API.
 * The height of a capital M is a common choice for measuring text height because it
 * typically provides a good approximation of the overall height of characters in a font.
 * @param tempCanvasContext temporary canvas
 * @returns actual text height
 */
function getCanvasTextHeight(tempCanvasContext: CanvasRenderingContext2D): number {
  var fontMetrics = tempCanvasContext.measureText('M');
  return fontMetrics.fontBoundingBoxAscent + fontMetrics.fontBoundingBoxDescent;
}

export function getNewValuesForSpeakerCrops({
  original_crop_dimension,
  original_crop_dimension_length,
  target_crop_dimension,
  target_crop_dimension_length,
  multiplier,
  originalVideoDimension,
  boundingBoxProps: { boundingBoxStartInDimension, boundingBoxEndInDimension },
  faceProps: { faceStartInDimension, faceEndInDimension },
  adjustedOriginalVideoDimensionStart,
  adjustedOriginalVideoDimensionEnd
}: {
  original_crop_dimension: number;
  original_crop_dimension_length: number;
  target_crop_dimension: number;
  target_crop_dimension_length: number;
  multiplier: number;
  originalVideoDimension: number;
  boundingBoxProps: { boundingBoxStartInDimension: number; boundingBoxEndInDimension: number };
  faceProps: { faceStartInDimension: number; faceEndInDimension: number };
  adjustedOriginalVideoDimensionStart: number;
  adjustedOriginalVideoDimensionEnd: number;
}): {
  crop_dimension: number;
  crop_dimension_length: number;
  new_target_crop_dimension: number;
  new_target_crop_dimension_length: number;
} {
  let crop_dimension = 0,
    crop_dimension_length = 0,
    new_target_crop_dimension = 0,
    new_target_crop_dimension_length = 0;

  /* #region Given the bounds from the BE, just place it in the canvas, no adjustments yet */
  crop_dimension = Math.round(original_crop_dimension);
  crop_dimension_length = Math.round(original_crop_dimension_length);
  new_target_crop_dimension =
    target_crop_dimension + (target_crop_dimension_length - crop_dimension_length * multiplier) / 2;
  new_target_crop_dimension_length = crop_dimension_length * multiplier;
  /* #endregion */

  // get dimensions of video that are outside the bounding box in the dimension we are cropping, converted to target dimensions
  const remainingTargetDimensionOnSideOfStart = Math.round(
    (boundingBoxStartInDimension - adjustedOriginalVideoDimensionStart) * originalVideoDimension * multiplier
  );
  const remainingTargetDimensionOnSideOfEnd = Math.round(
    (adjustedOriginalVideoDimensionEnd - boundingBoxEndInDimension) * originalVideoDimension * multiplier
  );

  // these are the dimensions of the target that are yet to be covered
  const availableTargetDimensionToExtendOnEitherSide = Math.round(
    (target_crop_dimension_length - new_target_crop_dimension_length) / 2
  );

  // if we can extend on either side, do it
  if (availableTargetDimensionToExtendOnEitherSide > 0) {
    if (
      remainingTargetDimensionOnSideOfEnd >= availableTargetDimensionToExtendOnEitherSide &&
      remainingTargetDimensionOnSideOfStart >= availableTargetDimensionToExtendOnEitherSide
    ) {
      crop_dimension = Math.round(original_crop_dimension - availableTargetDimensionToExtendOnEitherSide / multiplier);
      crop_dimension_length = Math.round(target_crop_dimension_length / multiplier);

      new_target_crop_dimension = target_crop_dimension;
      new_target_crop_dimension_length = target_crop_dimension_length;
    } else if (
      remainingTargetDimensionOnSideOfEnd < availableTargetDimensionToExtendOnEitherSide &&
      remainingTargetDimensionOnSideOfStart < availableTargetDimensionToExtendOnEitherSide
    ) {
      // TESTED FOR ALL CASES
      crop_dimension = adjustedOriginalVideoDimensionStart * originalVideoDimension;
      crop_dimension_length = Math.round(
        (adjustedOriginalVideoDimensionEnd - adjustedOriginalVideoDimensionStart) * originalVideoDimension
      );

      const remainingSpaceInTarget =
        availableTargetDimensionToExtendOnEitherSide -
        remainingTargetDimensionOnSideOfStart +
        (availableTargetDimensionToExtendOnEitherSide - remainingTargetDimensionOnSideOfEnd);

      new_target_crop_dimension = target_crop_dimension + remainingSpaceInTarget / 2;
      new_target_crop_dimension_length = target_crop_dimension_length - remainingSpaceInTarget;
    } else if (
      remainingTargetDimensionOnSideOfEnd >= availableTargetDimensionToExtendOnEitherSide &&
      remainingTargetDimensionOnSideOfStart < availableTargetDimensionToExtendOnEitherSide
    ) {
      const extraOnRightSide = Math.round(
        remainingTargetDimensionOnSideOfEnd - availableTargetDimensionToExtendOnEitherSide
      );
      const extraOnLeftSide = Math.round(
        availableTargetDimensionToExtendOnEitherSide - remainingTargetDimensionOnSideOfStart
      );

      if (extraOnRightSide >= extraOnLeftSide) {
        crop_dimension = 0;
        crop_dimension_length = Math.round(target_crop_dimension_length / multiplier);

        new_target_crop_dimension = target_crop_dimension;
        new_target_crop_dimension_length = target_crop_dimension_length;
      } else {
        // TESTED FOR ALL CASES
        const remainingSpaceInTarget =
          target_crop_dimension_length -
          (adjustedOriginalVideoDimensionEnd - adjustedOriginalVideoDimensionStart) *
            originalVideoDimension *
            multiplier;

        crop_dimension = adjustedOriginalVideoDimensionStart * originalVideoDimension;
        crop_dimension_length = Math.round(
          (adjustedOriginalVideoDimensionEnd - adjustedOriginalVideoDimensionStart) * originalVideoDimension
        );

        new_target_crop_dimension = target_crop_dimension + remainingSpaceInTarget / 2;
        new_target_crop_dimension_length = target_crop_dimension_length - remainingSpaceInTarget;
      }
    } else if (
      remainingTargetDimensionOnSideOfEnd < availableTargetDimensionToExtendOnEitherSide &&
      remainingTargetDimensionOnSideOfStart >= availableTargetDimensionToExtendOnEitherSide
    ) {
      const extraOnRightSide = Math.round(
        availableTargetDimensionToExtendOnEitherSide - remainingTargetDimensionOnSideOfEnd
      );
      const extraOnLeftSide = Math.round(
        remainingTargetDimensionOnSideOfStart - availableTargetDimensionToExtendOnEitherSide
      );

      if (extraOnLeftSide >= extraOnRightSide) {
        crop_dimension = Math.round(
          original_crop_dimension - (availableTargetDimensionToExtendOnEitherSide + extraOnRightSide) / multiplier
        );
        crop_dimension_length = Math.round(target_crop_dimension_length / multiplier);

        new_target_crop_dimension = target_crop_dimension;
        new_target_crop_dimension_length = target_crop_dimension_length;
      } else {
        // TESTED FOR ALL CASES
        const remainingSpaceInTarget =
          target_crop_dimension_length -
          (adjustedOriginalVideoDimensionEnd - adjustedOriginalVideoDimensionStart) *
            originalVideoDimension *
            multiplier;

        crop_dimension = adjustedOriginalVideoDimensionStart * originalVideoDimension;
        crop_dimension_length = Math.round(
          (adjustedOriginalVideoDimensionEnd - adjustedOriginalVideoDimensionStart) * originalVideoDimension
        );

        new_target_crop_dimension = target_crop_dimension + remainingSpaceInTarget / 2;
        new_target_crop_dimension_length = target_crop_dimension_length - remainingSpaceInTarget;
      }
    }
  }
  // if we cannot extend on either side, we need to crop the video
  else if (availableTargetDimensionToExtendOnEitherSide < 0) {
    crop_dimension = Math.round(
      original_crop_dimension + (original_crop_dimension_length - target_crop_dimension_length / multiplier) / 2
    );
    crop_dimension_length = Math.round(target_crop_dimension_length / multiplier);
    new_target_crop_dimension = target_crop_dimension;
    new_target_crop_dimension_length = target_crop_dimension_length;
  }

  /* #region Include face in crop handling starts here */
  if (crop_dimension > faceStartInDimension * originalVideoDimension) {
    crop_dimension = faceStartInDimension * originalVideoDimension;
  } else if (crop_dimension + crop_dimension_length < faceEndInDimension * originalVideoDimension) {
    crop_dimension = faceEndInDimension * originalVideoDimension - crop_dimension_length;
  }
  /* #endregion */

  return {
    crop_dimension,
    crop_dimension_length,
    new_target_crop_dimension,
    new_target_crop_dimension_length
  };
}
