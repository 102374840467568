import { useSyncExternalStore } from 'react';
import { ClipMetadata, SourceType } from '@/domains/asset';
import { Word } from '@/domains/transcript';
import useAnalytics from '@/hooks/useAnalytics';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { createContentClip } from '@/libs/clipContentUtil';
import { showErrorToast } from '@/libs/toast/toast';
import { prependAllClips, setClipGeneratingCount } from '@/stores/clip';
import { core } from '@/stores/core';
import { addUnseenClipIds } from '@/stores/unseenClips';

export default function useTranscriptPage() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const { incrementClipsUsed } = useFreeTrialHook();
  const { trackContentCreated } = useAnalytics();

  async function saveClip({ start, end }: Pick<ClipMetadata, 'start' | 'end'>, source: SourceType) {
    try {
      const newClip = await createContentClip(start, end, source);
      prependAllClips([{ ...newClip, isRemoved: false } as any]);
      addUnseenClipIds([newClip.id]);
      if (!coreStore.content?.is_sample_upload) {
        incrementClipsUsed(1);
      }
      trackContentCreated({
        asset: newClip,
        state: 'Created',
        source: 'Manual'
      });
      return newClip;
    } catch (err: any) {
      trackContentCreated({
        source: 'Manual',
        state: 'Failed',
        failureReason: err
      });
      showErrorToast("Creating the clip didn't quite work out. Double-check the time range and give it another shot.");
    }
  }

  const createClip = (words: Word[]) => {
    setClipGeneratingCount(1);
    const { start_time } = words[0];
    const { end_time = 0 } = words.at(-1) ?? {};
    return saveClip(
      {
        start: start_time,
        end: end_time
      },
      'USER'
    ).then(_newClip => {
      setClipGeneratingCount(0);
    });
  };

  return {
    createClip,
    isAudio: coreStore.content?.media_type === 'AUDIO'
  };
}
