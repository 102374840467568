import React, { useMemo, useSyncExternalStore } from 'react';
import { IconLoader2 } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { MediaContent } from '@goldcast/api/content';
import {
  convertBroadcastReason,
  formatToStandardDate,
  getSessionPreviewDetails,
  isSessionReadyForPreview
} from '../utils';
import SessionThumbnail from '../uiComponents/SessionThumbnail';
import SessionMenu from './SessionMenu';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge/Badge';
import { classnames } from '@/libs/utils';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';
import useEditFullRecording from '@/hooks/useEditFullRecording';

export default function SessionPreview({
  session,
  onDeleteSession
}: {
  session: MediaContent;
  onDeleteSession: () => void;
}) {
  const navigate = useNavigate();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const { isEditFullRecordingEnabled } = useEditFullRecording();

  const { isContentUploadInProgress, contentStatusText } = useMemo(() => {
    return getSessionPreviewDetails(session);
  }, [session]);

  const canPreviewSession = useMemo(() => {
    return isSessionReadyForPreview(session);
  }, [session]);

  const onContentClick = () => {
    if (!isContentUploadInProgress && !canPreviewSession) {
      return;
    }

    const projectId = session.project.id || session.project_id;
    const sessionId = session.id;

    if (isContentUploadInProgress && featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      navigate(`/import/${projectId}/${sessionId}`);
    } else {
      navigate(`/${projectId}/${sessionId}/${isEditFullRecordingEnabled ? 'recordings' : 'clips'}`);
    }
  };

  return (
    <button
      className={classnames(
        'flex w-[23rem] shrink-0 flex-col divide-y divide-slate-200/80 truncate !rounded-xl border !border-slate-200/80',
        {
          'cursor-pointer transition-all duration-[250ms] ease-in-out hover:scale-[1.0075] hover:shadow-lg hover:shadow-slate-200':
            session.batch_status === 'DONE',
          'cursor-not-allowed': session.batch_status !== 'DONE' && !isContentUploadInProgress
        }
      )}
      onClick={onContentClick}
      key={session.project.id + session.id}
      data-testid="session-preview"
    >
      {canPreviewSession ? (
        <div
          className="flex aspect-video grow cursor-pointer items-center justify-center !rounded-t-xl bg-slate-100"
          style={{
            backgroundColor: session.project.theme_obj?.event_background
          }}
          data-testid="session"
        >
          <SessionThumbnail iconSize={30} session={session} />
        </div>
      ) : (
        <div className="flex aspect-video grow items-center justify-center !rounded-t-xl bg-slate-200">
          <div className="flex flex-col items-center whitespace-normal p-3 text-center text-slate-500">
            {session.batch_status === 'FAILED' ? (
              <div className="flex h-12 w-8 items-center justify-center">
                <Icon
                  name={session.reason === 'LANGUAGE_NOT_SUPPORTED' ? 'IconLanguageOff' : 'IconSquareRoundedX'}
                  size={24}
                  stroke={1.5}
                />
              </div>
            ) : (
              <div className="relative" data-testid="processing-icon">
                <IconLoader2 size={24} className="animate-spin" />
              </div>
            )}
            <div className="mt-1.5 text-sm">{contentStatusText}</div>
            {session.reason && <div className="text-xs">{convertBroadcastReason(session.reason as string)}</div>}
          </div>
        </div>
      )}
      <div className="flex w-full items-center gap-2 space-x-2 !rounded-b-xl bg-white px-5 py-3.5">
        <div className="grow truncate">
          <div className="truncate text-left text-sm leading-tight tracking-tight">{session.title}</div>
          <div className="text-left text-xs text-slate-500">{formatToStandardDate(session.end_time)}</div>
        </div>
        {session.batch_status !== 'DONE' && (
          <div className="shrink-0">
            <Badge data-testid="session-badge" variation="gray">
              {contentStatusText}
            </Badge>
          </div>
        )}
        <SessionMenu session={session} onDelete={onDeleteSession} position="top" />
      </div>
    </button>
  );
}
