import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import useMediaContent from '../useMediaContent';
import EventFilter from '../Filters/EventFilter';
import SearchExpandableInput from '../uiComponents/SearchExpandableInput';
import SessionTableSkeleton from '../SessionTable/SessionTableSkeleton';
import NoData from '../RecentEvents/NoData';
import ErrorState from '../RecentEvents/ErrorState';
import NoMatch from '../AssetsTable/NoMatch';
import SessionTable from '../SessionTable/SessionTable';
import { useAppContext } from '@/context/AppContext/AppContext';
import SectionHeader from '@/components/atoms/SectionHeader';
import { currentUser } from '@/stores/user';

export default function SessionsListModal() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);

  const { mediaContent, showErrorState, fetchMediaContentList } = useMediaContent();

  const { isFetching: loading, refetch } = useQuery({
    queryKey: ['new-content-events', selectedEvents, searchTerm],
    queryFn: () =>
      fetchMediaContentList({
        organization: currentUser.getSnapshot()?.organization as string,
        limit: 200,
        selectedEvents,
        searchTerm
      })
  });

  const onEventSelect = useCallback(options => {
    setSelectedEvents(options);
  }, []);

  const clearEvents = useCallback(() => {
    setSelectedEvents([]);
  }, []);

  const clearAllFilters = useCallback(() => {
    setSelectedEvents([]);
    setSearchTerm('');
  }, []);

  const hasFilters = useMemo(() => selectedEvents.length > 0 || searchTerm, [selectedEvents, searchTerm]);
  const noData = !loading && mediaContent.length === 0 && !hasFilters;

  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  return (
    <div className="h-[28rem] overflow-y-scroll">
      <div className="flex w-full items-center justify-between px-3 pt-3.5">
        {!is_content_lab_standalone ? (
          <EventFilter selected={selectedEvents} onSelect={onEventSelect} onClear={clearEvents} />
        ) : (
          <SectionHeader headerText="All Recordings" />
        )}
        <div>
          <SearchExpandableInput searchTerm={searchTerm} onUpdate={setSearchTerm} />
        </div>
      </div>
      <div className="my-2 px-3">
        {loading ? (
          <SessionTableSkeleton />
        ) : showErrorState ? (
          <ErrorState />
        ) : mediaContent.length === 0 && hasFilters ? (
          <NoMatch clearAllFilters={clearAllFilters} />
        ) : noData ? (
          <NoData />
        ) : (
          mediaContent.map(session => <SessionTable session={session} key={session.id} onDeleteSession={refetch} />)
        )}
      </div>
    </div>
  );
}
