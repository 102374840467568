import {
  IconArticle,
  IconArticleFilled,
  IconBulb,
  IconBulbFilled,
  IconFile,
  IconFileFilled,
  IconMail,
  IconMailFilled,
  IconMovie,
  IconPlayerPlay,
  IconPlayerPlayFilled,
  IconStar,
  IconStarFilled,
  IconThumbUp,
  IconThumbUpFilled
} from '@tabler/icons-react';

export const getNavItems = ({
  broadcastId,
  eventId,
  unseenCount,
  isEditFullRecordingEnabled
}: {
  broadcastId: string;
  eventId: string;
  unseenCount: number;
  isEditFullRecordingEnabled: boolean;
}) => [
  {
    icon: IconMovie,
    filledIcon: IconMovie,
    label: 'Recording',
    href: `/${eventId}/${broadcastId}/recordings`,
    visible: isEditFullRecordingEnabled
  },
  {
    icon: IconPlayerPlay,
    filledIcon: IconPlayerPlayFilled,
    label: 'Clips',
    href: `/${eventId}/${broadcastId}/clips`,
    unseenCount,
    visible: true
  },
  {
    icon: IconThumbUp,
    filledIcon: IconThumbUpFilled,
    label: 'Social',
    href: `/${eventId}/${broadcastId}/social`,
    visible: true
  },
  {
    icon: IconArticle,
    filledIcon: IconArticleFilled,
    label: 'Blog',
    href: `/${eventId}/${broadcastId}/blog`,
    visible: true
  },
  {
    icon: IconMail,
    filledIcon: IconMailFilled,
    label: 'Email',
    href: `/${eventId}/${broadcastId}/email`,
    visible: true
  },
  {
    icon: IconStar,
    filledIcon: IconStarFilled,
    label: 'Takeaways',
    href: `/${eventId}/${broadcastId}/takeaways`,
    visible: true
  },
  {
    icon: IconBulb,
    filledIcon: IconBulbFilled,
    label: 'Custom',
    href: `/${eventId}/${broadcastId}/custom`,
    visible: true
  },
  {
    icon: IconFile,
    filledIcon: IconFileFilled,
    label: 'Transcript',
    href: `/${eventId}/${broadcastId}/transcript`,
    visible: true
  }
];
