import { PaginatedSpeakerResponseList, UserSpeakerListAPIQueryParams, userSpeakerList } from '@goldcast/api/content';
import { RawWord, SpeakerWithDetails, TranscriptContextData, TranscriptContextType } from './TranscriptContextTypes';
import { Word } from '@/domains/transcript';
import { currentClip } from '@/stores/clip';
import { CLOUDFRONT_ASSET_URL, getSpeakerImage } from '@/libs/utils';
import { getEnvConfig } from '@/constants';
import { core } from '@/stores/core';
import { showErrorToast } from '@/libs/toast/toast';
import { updatePlayerStoreWithId } from '@/stores/playerV2';
import { TRIM_POSITION_OFFSET } from '@/components/molecules/Transcript/constants';
import { isCustomUpload } from '@/libs/clipContentUtil';

export function loadSpeakers(queryParams: UserSpeakerListAPIQueryParams) {
  return userSpeakerList({ queryParams })
    .then(speakers =>
      ((speakers as PaginatedSpeakerResponseList['results']) || []).reduce((acc, speaker) => {
        return { ...acc, [speaker.label as unknown as string]: speaker };
      }, {})
    )
    .catch(error => {
      showErrorToast(
        'Uh-oh! We hit a snag loading the speakers. Quick check: is your internet feeling okay? If it looks good, give it another shot.'
      );
      console.error('Error loading speakers', error);
      return {};
    });
}

export const defaultState: TranscriptContextType = {
  transcript: [],
  end: 0,
  speakersWithDetails: {},
  usedSpeakers: [],
  trimming: false,
  timeSrtIndexArray: []
};

function getFilteredWords(words: RawWord[], clip_start_time?: number, clip_end_time?: number) {
  return clip_start_time === undefined || clip_end_time === undefined
    ? words
    : words.filter(({ start_time, end_time }, index) => {
        const actual_start_time = start_time ? start_time : words[index - 1]?.start_time;
        const actual_end_time = end_time ? end_time : words[index + 1]?.end_time;

        return (
          clip_start_time === undefined ||
          clip_end_time === undefined ||
          (+actual_start_time >= clip_start_time && +actual_end_time <= clip_end_time)
        );
      });
}

interface TranscriptReturn {
  transcript: Word[];
  /**
   * This is required for showing speaker identification at clip level
   */
  fullTranscript: Word[];
}

export function getTranscript(
  cacheSuffix: string,
  event: string,
  broadcast: string,
  clip_start_time?: number,
  clip_end_time?: number
): Promise<TranscriptReturn> {
  return fetch(CLOUDFRONT_ASSET_URL(event, broadcast)(`transcript.json?${cacheSuffix}`), { cache: 'force-cache' })
    .then(res => {
      if (res.status >= 400)
        showErrorToast(
          "Oops! We're having trouble loading the transcript. Please check your connection and try again."
        );
      return res.json();
    })
    .then(words => {
      if (!Array.isArray(words)) {
        return {
          transcript: [],
          fullTranscript: []
        };
      }

      const filteredWords = getFilteredWords(words, clip_start_time, clip_end_time);

      return {
        transcript: getTranscriptDataInRequiredFormat(filteredWords),
        fullTranscript: getTranscriptDataInRequiredFormat(words)
      };
    });
}

function getTranscriptDataInRequiredFormat(words: RawWord[]) {
  return words.map(({ alternatives, start_time, end_time, ...word }: RawWord, index: number) => {
    const startTime = words[index + 1]?.start_time;
    const endTime = words[index - 1]?.end_time;

    return {
      ...word,
      start_time: start_time ? +start_time : endTime ? +endTime : endTime,
      end_time: end_time ? +end_time : startTime ? +startTime : startTime,
      content: alternatives[0].content ?? '',
      index
    };
  });
}

export type TranscriptMap = Record<string, { url: string; start: number }>;

function formTranscriptMap(
  data: any,
  clip_start_time: number,
  clip_end_time: number,
  event: string,
  broadcast: string
) {
  return Object.keys(data).reduce((acc, key) => {
    let speakerObject = data[key];
    if (Array.isArray(data[key])) {
      speakerObject = data[key][0];
      for (let i = data[key].length - 1; i >= 0; i--) {
        const { start_time, individual_start_time, duration } = data[key][i];
        const translation = +start_time - +individual_start_time;

        if (clip_start_time - translation >= 0 && clip_end_time - translation <= duration) {
          speakerObject = { ...data[key][i] };
          break;
        }
      }
    }

    const { file_label, individual_start_time, start_time } = speakerObject;
    acc[key] = {
      url: `${getEnvConfig('SPEAKER_VIDEO_URL')}${event}/${broadcast}/${file_label.split('-').pop()}/stream.m3u8`,
      start: +start_time - +individual_start_time
    };
    return acc;
  }, {} as TranscriptMap);
}

export function getTranscriptMap(
  cacheSuffix: string,
  event: string,
  broadcast: string,
  clip_start_time?: number,
  clip_end_time?: number
) {
  if (!clip_end_time || !clip_start_time || isCustomUpload()) {
    return Promise.resolve({});
  }

  return fetch(CLOUDFRONT_ASSET_URL(event, broadcast)(`transcript_mapping.json?${cacheSuffix}`), {
    cache: 'force-cache'
  })
    .then(res => {
      if (res.status === 404) {
        return { noData: true };
      }
      return res.json();
    })
    .then(data => {
      if (data.noData) {
        return data;
      }
      return formTranscriptMap(data, clip_start_time, clip_end_time, event, broadcast);
    });
}

export function getSpeakersParams(contentId: string): UserSpeakerListAPIQueryParams {
  const { content } = core.getSnapshot();
  if (content && content?.media_source_type !== 'UPLOAD') return { broadcast: contentId };
  return { upload: contentId };
}

export function initTranscript(
  cacheSuffix: string,
  eventId: string,
  broadcastId: string,
  clip_start_time?: number,
  clip_end_time?: number
): Promise<[TranscriptReturn, TranscriptMap, Record<string, SpeakerWithDetails>]> {
  const startTime = clip_start_time !== undefined ? Math.min(clip_start_time - TRIM_POSITION_OFFSET) : clip_start_time;
  const endTime = clip_end_time !== undefined ? Math.max(clip_end_time + TRIM_POSITION_OFFSET) : clip_end_time;

  return Promise.allSettled([
    getTranscript(cacheSuffix, eventId!, broadcastId!, startTime, endTime),
    getTranscriptMap(cacheSuffix, eventId!, broadcastId!, clip_start_time, clip_end_time),
    loadSpeakers(getSpeakersParams(broadcastId!))
  ]).then(([transcriptResult, transcriptMapResult, speakersResult]) => {
    if (transcriptResult.status === 'rejected') {
      throw transcriptResult.reason;
    }

    return [
      transcriptResult.value,
      transcriptMapResult.status === 'rejected' ? {} : transcriptMapResult.value,
      speakersResult.status === 'rejected' ? {} : speakersResult.value
    ];
  });
}

const speakerColors = ['text-teal-500', 'text-rose-400', 'text-cyan-700', 'text-violet-500'];
const speakerBgColors = ['bg-teal-500', 'bg-rose-400', 'bg-cyan-700', 'bg-violet-500'];

export const formTranscriptContextData = (
  transcriptResult: TranscriptReturn,
  transcriptMap,
  sessionSpeakers,
  clipId: string
): TranscriptContextData => {
  const transcript = transcriptResult.transcript;
  const fullTranscript = transcriptResult.fullTranscript;

  const speakersIds = Object.keys(sessionSpeakers);
  const speakers: Record<string, SpeakerWithDetails> = {};

  function getSpeaker(id: string) {
    if (!speakers[id]) {
      if (!speakersIds.includes(id)) {
        speakersIds.push(id);
      }
      const index = speakersIds.indexOf(id);
      const speaker = sessionSpeakers[id];
      speakers[id] = {
        ...(speaker as any),
        key: id,
        id: speaker?.id ?? index,
        profile_picture_url: getSpeakerImage(speaker?.profile_picture_url),
        color: speakerColors[index % speakerColors.length],
        bgColor: speakerBgColors[index % speakerBgColors.length],
        speakingSlots: {},
        video: transcriptMap[id] || {}
      };
    }
    return speakers[id];
  }

  Object.keys(sessionSpeakers).forEach(getSpeaker);

  let start = 0;
  let end = 0;
  fullTranscript.forEach((word, index) => {
    if (!start) {
      start = word.start_time;
    }
    end = word.end_time ?? end;
    if (word.speaker_label !== fullTranscript[index + 1]?.speaker_label) {
      getSpeaker(word.speaker_label).speakingSlots[start] = end;
      start = 0;
    }
  });

  const srtIndexes: number[] = [];
  const timeSrtIndexArray: TranscriptContextData['timeSrtIndexArray'] = [];

  transcript.forEach(word => {
    if (srtIndexes.includes(word.srt_index)) {
      return;
    }
    srtIndexes.push(word.srt_index);
    timeSrtIndexArray.push({ startTime: word.start_time, srtIndex: word.srt_index });
  });

  if (clipId) {
    updatePlayerStoreWithId(clipId, { currentSrtIndex: srtIndexes[0] });
  }

  return {
    end,
    transcript,
    speakersWithDetails: speakers,
    usedSpeakers: getUsedSpeakers(speakers),
    timeSrtIndexArray
  };
};

export function isDeleted(start_time: number, clipId) {
  const clip = currentClip.getSnapshot()[clipId];
  if (!clip?.asset_metadata?.excludes) return false;

  return clip.asset_metadata.excludes.reduce((time, [start, end]) => {
    const t = time || start_time;
    if (start <= t && end >= t) {
      return end;
    }
    return time;
  }, 0);
}

export function getUsedSpeakers(allSpeakers: Record<string, SpeakerWithDetails>): SpeakerWithDetails[] {
  return Object.values(allSpeakers).filter(speaker => !speaker.parent_id);
}

export function getUnusedSpeakers(allSpeakers: Record<string, SpeakerWithDetails>): SpeakerWithDetails[] {
  return Object.values(allSpeakers).filter(speaker => !!speaker.parent_id);
}

function getAllCachedSuffixesAndCurrentCacheSuffixKey() {
  const allCacheSuffixes = localStorage.getItem('transcriptCacheSuffix');
  const { project_id: projectId, id: contentId } = core.getSnapshot().content!;

  const cacheSuffixKey = `${projectId}:${contentId}`;

  return { allCacheSuffixes, cacheSuffixKey };
}

export function getNewCacheSuffix() {
  const { allCacheSuffixes, cacheSuffixKey } = getAllCachedSuffixesAndCurrentCacheSuffixKey();

  let cacheSuffix = Math.random().toString();

  if (!allCacheSuffixes) {
    setNewCacheSuffix(cacheSuffix);
    return cacheSuffix;
  }

  const parsedCacheSuffixes = JSON.parse(allCacheSuffixes);
  const currentCacheSuffix = parsedCacheSuffixes[cacheSuffixKey];

  if (currentCacheSuffix) {
    cacheSuffix = currentCacheSuffix;
  } else {
    setNewCacheSuffix(cacheSuffix);
  }

  return cacheSuffix;
}

/**
 * Use this function to set the cache suffix for the transcript
 * This is required to invalidate the cache and fetch the latest transcript in case of MAIN/FULL transcript editing actions
 */
export function setNewCacheSuffix(cacheSuffix: string) {
  const { allCacheSuffixes, cacheSuffixKey } = getAllCachedSuffixesAndCurrentCacheSuffixKey();

  const parsedCacheSuffixes = JSON.parse(allCacheSuffixes || '{}');

  localStorage.setItem(
    'transcriptCacheSuffix',
    JSON.stringify({
      ...parsedCacheSuffixes,
      [cacheSuffixKey]: cacheSuffix
    })
  );
}
