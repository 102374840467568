import { useMemo, useSyncExternalStore } from 'react';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';

export default function useEditFullRecording() {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);

  const isEditFullRecordingEnabled = useMemo(() => {
    return featureFlags[FeatureFlagKeys.Use_CL_Edit_Full_Recording];
  }, [featureFlags]);

  return {
    isEditFullRecordingEnabled
  };
}
