import React, { useEffect, useMemo, useSyncExternalStore } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { IconBrandYoutube } from '@tabler/icons-react';
import ClipListItemThumbnail from './ClipListItemThumbnail';
import { handleClipLockedError } from '../Clip/utils';
import { Clip } from '@/domains/asset';
import { formatTime } from '@/libs/core';
import { classnames } from '@/libs/utils';
import { removeUnseenClipId, unseenClipIds } from '@/stores/unseenClips';
import IconButton from '@/components/atoms/Button/IconButton';
import AssetRating from '@/components/molecules/AssetRating/AssetRating';
import useSavedSearch from '@/hooks/useSavedSearch';

export default function ClipsListItem({
  clip,
  onClipSelect,
  isCollapsed,
  children
}: {
  clip: Clip;
  onClipSelect: (clip: Clip) => void;
  isCollapsed: boolean;
  children?: React.ReactNode;
}) {
  const clipDuration = useMemo(() => {
    // First take duration from config because it includes deletes, intro, outro
    // If there is no config.duration, take duration from metadata because it includes deletes
    // If there no metadata duration, use end - start
    const duration = clip.asset_metadata.config
      ? clip.asset_metadata.config.duration
      : clip.asset_metadata.duration
      ? clip.asset_metadata.duration
      : clip.asset_metadata.end - clip.asset_metadata.start;
    return formatTime(duration);
  }, [clip]);
  const [searchParams] = useSearchParams();
  const activeClipId = useMemo(() => searchParams.get('activeClipId'), [searchParams]);
  const isClipActive = activeClipId === clip.id;
  const unseenClipsStore = useSyncExternalStore(unseenClipIds.subscribe, unseenClipIds.getSnapshot);
  const isClipUnseen = unseenClipsStore.includes(clip.id);
  const { isSavedSearchPage } = useSavedSearch();

  const showAssetRating = useMemo(() => {
    return clip.asset_metadata.source === 'AI';
  }, [clip]);

  useEffect(() => {
    if (isClipActive) {
      const element = document.getElementById(clip.id);
      element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      removeUnseenClipId(clip.id);
    }
  }, [isClipActive, clip.id]);

  return (
    <div
      id={clip.id}
      role="button"
      onClick={() => onClipSelect(clip)}
      className={classnames('group flex w-full cursor-pointer items-stretch space-x-4 px-5 py-3 pr-3 text-left', {
        '!bg-slate-100': isClipActive,
        'bg-orange-50': isClipUnseen,
        'hover:bg-slate-50': !children
      })}
    >
      <div
        className={classnames(
          'flex aspect-[4/3] w-56 shrink-0 flex-col items-center justify-center rounded-xl bg-slate-200',
          {
            '!bg-orange-100': isClipUnseen && !isClipActive
          }
        )}
        id={clip.asset_metadata.id}
        title={`${clip.title}\n\n${clip.asset_metadata.description || ''}`}
      >
        <ClipListItemThumbnail clip={clip} isClipActive={isClipActive} isClipUnseen={isClipUnseen} />
      </div>

      <Transition
        show={!isCollapsed}
        appear
        enter="transition-all delay-300 duration-300 overflow-hidden"
        enterFrom="opacity-0 translate-x-[-2rem]"
        enterTo="opacity-100"
        leave="transition-all duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex h-full grow flex-col justify-between pt-0.5">
          <div className="flex w-full items-center justify-between space-x-1">
            <div className="flex grow justify-between space-x-1">
              <div title={clip.asset_metadata.description}>
                <div className="line-clamp-3 grow text-sm font-medium leading-tight">{clip.title}</div>
                {isSavedSearchPage ? (
                  <div className="mt-1.5 flex shrink-0 items-center space-x-0.5 text-xs text-slate-500 hover:underline">
                    <IconBrandYoutube size={16} className="shrink-0" />
                    <Link
                      to={`/${clip.content.project.id}/${clip.content.id}/transcript`}
                      title={clip.content.title}
                      target="_blank"
                    >
                      <span className="line-clamp-1">{clip.content.title}</span>
                    </Link>
                  </div>
                ) : clip.asset_metadata.description ? (
                  <div className="mt-1 line-clamp-3 shrink-0 text-sm text-slate-600">
                    {clip.asset_metadata.description}
                  </div>
                ) : null}
              </div>
              {children}
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="text-xs text-slate-600">{clipDuration}</div>
            <div className="flex text-slate-600">
              {showAssetRating && <AssetRating assetId={clip?.id} rating={clip?.rating} assetType="CLIP" />}
              {clip.locked ? (
                <IconButton
                  icon="IconLock"
                  title={`Clip is published in ${clip.content.project.title}'s on-demand page and cannot be edited. Please un-publish to continue editing.`}
                  onClick={() => handleClipLockedError(clip)}
                  trackingId="clips-list-item-locked-button"
                  variation="outline"
                  iconClassName="h-4 w-4 text-slate-600"
                  buttonClassName="h-6 w-4"
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
