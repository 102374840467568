import { memo, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import ClipUndoRedo from '@/components/molecules/ClipUndoRedo';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import ClipSharePopup from '@/components/molecules/SharePopup/ClipSharePopup/ClipSharePopup';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import useSavedSearch from '@/hooks/useSavedSearch';
import ContentSettings from '@/components/molecules/ContentSettings';
import SavedSearchTitlePopup from '@/Pages/SavedSearch/SavedSearchTitlePopup';
import SessionTitlePopup from '@/Pages/SessionDetail/SessionTitlePopup';
import NewTemplateButton from '@/Pages/Clip/SideBar/TemplatesSideBar/NewTemplateButton';

function ClipHeader() {
  const { broadcastId } = useParams<{
    broadcastId: string;
  }>();

  const { clipData } = useClipsContext();

  const location = useLocation();
  const { isSavedSearchPage } = useSavedSearch();

  const isRecordingsPage = useMemo(() => {
    return location.pathname.includes('/recordings');
  }, [location.pathname]);

  return (
    <div className="relative flex w-full items-center justify-between p-4">
      {isSavedSearchPage ? <SavedSearchTitlePopup /> : <SessionTitlePopup />}
      <div className="flex items-center space-x-3">
        <FreeTrialUpgradeNag />
        <ClipUndoRedo isInline={true} />
        {broadcastId && <DownloadsPopup contentId={broadcastId} />}
        {!isRecordingsPage && <NewTemplateButton className="bg-white" />}
        <ClipSharePopup activeClipId={clipData.id} downloadIntent={location.state?.downloadIntent} />
        {isSavedSearchPage && <ContentSettings />}
      </div>
    </div>
  );
}

export default memo(ClipHeader);
