import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { INACTIVE_WORD_OPACITY, INITIAL_CAPTIONS_TOP_MARGIN_PERCENTAGE, RESIZE_BOX_PADDING } from '../constants';
import useCaptionsDrag from '../useCaptionsDrag';
import CaptionsMenu from './CaptionsMenu/CaptionsMenu';
import { getCaptionsLineHeight, getCaptionsPlacementMargin } from './utils';
import CaptionsWords from './CaptionsMenu/CaptionsWords';
import { CAPTIONS_MIN_WIDTH } from './CaptionsMenu/constants';
import { PreviewTemplate } from '../../SideBar/types';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { classnames } from '@/libs/utils';
import { changeCaptionsPosition, updateCaptionStyles } from '@/stores/clip';
import { CaptionStyle, CaptionsPlacement, CaptionsPosition, ClipMetadata } from '@/domains/asset';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { generateThumbnail } from '@/libs/thumbnail';
import { getColorWithOpacity } from '@/components/atoms/ColorPicker/utils';

export default function CanvasPlayerDraggableCaptions({
  captionFontSize,
  clipMetadata,
  clipId,
  isPreview = false,
  isDisabled
}: {
  captionFontSize: number;
  clipMetadata: ClipMetadata | PreviewTemplate;
  clipId: string;
  isPreview?: boolean;
  isDisabled: boolean;
}) {
  const { playerStore } = useClipsContext();
  const captionsWrapper = useRef<HTMLDivElement>(null);
  const draggableElement = useRef<HTMLDivElement>(null);
  const textElement = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const [areCaptionsSelected, setAreCaptionsSelected] = useState(false);
  const isSavingPositions = useRef(false);
  const shouldUpdateCaptionPositions = useRef(false);

  const showCaption = useMemo(() => {
    return clipMetadata.subtitle && clipMetadata.layout !== 'AUDIOGRAM';
  }, [clipMetadata.subtitle, clipMetadata.layout]);

  const updatingPositions = useRef<CaptionsPosition | null>(null);

  function showCaptionsMenu() {
    setAreCaptionsSelected(true);
    EventBus.dispatch(CustomEvents.OpenEditCaptions);
  }

  useEffect(() => {
    if (areCaptionsSelected) return;
    const eventListener = EventBus.on(CustomEvents.OpenCaptionsMenu, showCaptionsMenu);
    return () => {
      EventBus.off(CustomEvents.OpenCaptionsMenu, eventListener);
    };
  }, [areCaptionsSelected]);

  function selectCaptions() {
    if (!isDisabled) showCaptionsMenu();
  }

  const lineHeight = useMemo(() => {
    return getCaptionsLineHeight(captionFontSize);
  }, [captionFontSize]);

  const onCaptionStyleChanging = useCallback(() => {
    shouldUpdateCaptionPositions.current = true;
  }, []);

  const onCaptionStyleChanged = useCallback(() => {
    // Prevent updating positions on BE if we only click on captions without drag
    if (!updatingPositions.current || !shouldUpdateCaptionPositions.current) {
      return;
    }
    showCaptionsMenu();
    isSavingPositions.current = true;
    changeCaptionsPosition({ clipId: clipId, caption_positions: updatingPositions.current, skipUpdate: true });
    updateCaptionStyles(clipId, { placement: undefined }, true);
    shouldUpdateCaptionPositions.current = false;
  }, [clipId]);

  const { handleMouseDown, positions, isDragging, setPositions } = useCaptionsDrag({
    captionsWrapper,
    draggableElement,
    isDisabled,
    onDragEnd: onCaptionStyleChanged,
    onDrag: onCaptionStyleChanging
  });

  const dimensions = useMemo(() => {
    return {
      width: 95,
      height: clipMetadata.size === 'LANDSCAPE' ? 18 : 15
    };
  }, [clipMetadata.size]);

  useEffect(() => {
    if (isDisabled) return;
    function handleOutsideClick(e: MouseEvent) {
      if (!areCaptionsSelected) return;
      if (!captionsWrapper.current?.contains(e.target as Node)) {
        setAreCaptionsSelected(false);
        // Timeout to make sure that captions menu is removed from DOM before thumbnail generate
        setTimeout(() => generateThumbnail(clipId), 600);
      }
    }
    document.addEventListener('pointerdown', handleOutsideClick);
    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
  }, [isDisabled, areCaptionsSelected]);

  const isSmallFontSize = useMemo(() => {
    return clipMetadata.caption_styles?.scale === 0.5;
  }, [clipMetadata.caption_styles?.scale]);

  const isBackgroundApplied = useMemo(() => {
    return clipMetadata.caption_styles?.animation === CaptionStyle.Background;
  }, [clipMetadata.caption_styles?.animation]);

  const isCapsLock = useMemo(() => {
    return !!clipMetadata.caption_styles?.caps;
  }, [clipMetadata.caption_styles?.caps]);

  useEffect(() => {
    if (captionsWrapper.current && textElement.current) {
      const playerElementRect = captionsWrapper.current.parentElement?.getBoundingClientRect();
      const textRect = textElement.current.getBoundingClientRect();

      if (playerElementRect) {
        const textX = ((textRect.left - playerElementRect.left) / playerElementRect.width) * 100;
        const textY = ((textRect.top - playerElementRect.top) / playerElementRect.height) * 100;

        updatingPositions.current = {
          y: positions.top,
          x: positions.left,
          height: dimensions.height,
          width: dimensions.width,
          textX,
          textY
        };
      }
    }
  }, [positions, dimensions]);

  const [isHorizontalSnapVisible, setIsHorizontalSnapVisible] = useState(false);
  const [isVerticalSnapVisible, setIsVerticalSnapVisible] = useState(false);

  useEffect(() => {
    if (!isDragging) {
      setIsHorizontalSnapVisible(false);
      setIsVerticalSnapVisible(false);
      return;
    }
    setIsVerticalSnapVisible(Math.abs(positions.left + dimensions.width / 2 - 50) < 0.5);
    setIsHorizontalSnapVisible(Math.abs(positions.top + dimensions.height / 2 - 50) < 0.5);
  }, [positions, isDragging, dimensions.width]);

  useEffect(() => {
    if (isVerticalSnapVisible) {
      setPositions({
        top: positions.top,
        left: (100 - dimensions.width) / 2
      });
    }
  }, [dimensions.width, isVerticalSnapVisible, positions.top, setPositions]);

  useEffect(() => {
    if (isHorizontalSnapVisible) {
      setPositions({
        top: (100 - dimensions.height) / 2,
        left: positions.left
      });
    }
  }, [dimensions.height, isHorizontalSnapVisible, positions.left, setPositions]);

  const applyPlacement = useCallback(
    (placement: CaptionsPlacement) => {
      if (!placement) return;
      const playerElementRect = captionsWrapper.current?.parentElement?.getBoundingClientRect();
      const captionsRect = draggableElement.current?.getBoundingClientRect();
      if (playerElementRect && captionsRect) {
        const initialLeft = ((playerElementRect.width - captionsRect.width) / 2 / playerElementRect.width) * 100;
        const margin = getCaptionsPlacementMargin(playerElementRect.height, captionsRect.height, placement);
        const initialTop =
          ((playerElementRect.height - captionsRect.height - margin) / playerElementRect.height) * 100 -
          INITIAL_CAPTIONS_TOP_MARGIN_PERCENTAGE;
        const playerPadding = (playerElementRect.width / 100) * 2.5;

        changeCaptionsPosition({
          clipId: clipId,
          caption_positions: {
            x: initialLeft,
            y: initialTop,
            width: dimensions.width,
            height: dimensions.height,
            textY: initialTop + (playerPadding / playerElementRect.height) * 100,
            textX: initialLeft + 10
          },
          skipUpdate: true
        });
      }
    },
    [clipMetadata.size, clipId, dimensions.height, dimensions.width]
  );

  const applyClipDataPositions = useCallback(() => {
    if (!showCaption) return;
    if (clipMetadata.caption_positions) {
      setPositions({
        top: clipMetadata.caption_positions.y,
        left: clipMetadata.caption_positions.x
      });
      return;
    }
    const playerElementRect = captionsWrapper.current?.parentElement?.getBoundingClientRect();
    const captionsRect = draggableElement.current?.getBoundingClientRect();
    if (playerElementRect && captionsRect) {
      const initialLeft = (100 - dimensions.width) / 2;
      const initialTop =
        ((playerElementRect.height - captionsRect.height) / playerElementRect.height) * 100 -
        INITIAL_CAPTIONS_TOP_MARGIN_PERCENTAGE;

      setPositions({
        top: initialTop,
        left: initialLeft
      });
    }
  }, [clipMetadata.caption_positions, clipMetadata.size, setPositions, showCaption]);

  useEffect(() => {
    applyClipDataPositions();
  }, [clipMetadata]);

  useEffect(() => {
    if (isSavingPositions.current) {
      isSavingPositions.current = false;
      return;
    }
    applyClipDataPositions();
  }, [clipMetadata.caption_positions, applyClipDataPositions]);

  const speakerSegmentContextData = useSpeakerSegmentContext();

  const words = useMemo(() => {
    const index = isPreview
      ? speakerSegmentContextData?.startSegment ?? playerStore.currentSrtIndex
      : playerStore.currentSrtIndex;
    return speakerSegmentContextData?.srtCaptions?.[index] || [];
  }, [playerStore.currentSrtIndex, speakerSegmentContextData?.srtCaptions]);

  const isSizeChanging = useRef<boolean | undefined>(undefined);

  const onSizeChange = useCallback(() => {
    isSizeChanging.current = true;
  }, [isSizeChanging]);

  useEffect(() => {
    if (areCaptionsSelected && isSizeChanging.current) {
      setTimeout(() => {
        const textRect = textContainerRef.current?.getBoundingClientRect();
        const playerElementRect = captionsWrapper?.current?.parentElement?.getBoundingClientRect();
        if (textRect && playerElementRect) {
          const minWidth = CAPTIONS_MIN_WIDTH[clipMetadata.size] * (clipMetadata.caption_styles?.scale || 1);
          const newWidth = Math.max(minWidth, (textRect.width / playerElementRect.width) * 100 + 5);
          const newHeight = (textRect.height / playerElementRect.height) * 100 + 5;
          if (newWidth + positions.left > 100 || newHeight + positions.top > 100) {
            setPositions({
              left: newWidth + positions.left > 100 ? 100 - newWidth : positions.left,
              top: newHeight + positions.top > 100 ? 100 - newHeight : positions.top
            });
          }
        }
        isSizeChanging.current = false;
      });
    }
  }, [clipId, clipMetadata.caption_styles?.scale]);

  useEffect(() => {
    if (isSizeChanging.current === false && updatingPositions.current) {
      changeCaptionsPosition({ clipId: clipId, caption_positions: updatingPositions.current, skipUpdate: true });
      updateCaptionStyles(clipId, { placement: undefined }, true);
    }
  }, [isSizeChanging.current]);

  const captionsBackgroundColor = useMemo(() => {
    return !isBackgroundApplied
      ? null
      : getColorWithOpacity(clipMetadata.caption_styles?.background_style_color, INACTIVE_WORD_OPACITY);
  }, [clipMetadata.caption_styles?.background_style_color, isBackgroundApplied]);

  return (
    <div ref={captionsWrapper} className={showCaption ? '' : 'hidden'}>
      {isVerticalSnapVisible && (
        <div
          className="absolute left-[50%] h-full w-[1.5px] bg-pink-500 transition-all duration-75 ease-in-out"
          data-thumbnail-skip-class="bg-pink-500"
        ></div>
      )}
      {isHorizontalSnapVisible && (
        <div className="absolute top-[50%] h-[1.5px] w-full bg-pink-500" data-thumbnail-skip-class="bg-pink-500"></div>
      )}
      <div
        ref={draggableElement}
        className={classnames(
          'absolute flex select-none items-center justify-center ring-0 ring-deep-orange',
          isDisabled ? '' : areCaptionsSelected ? 'ring-2' : 'hover:ring-2',
          isDisabled ? '' : isDragging ? 'cursor-grabbing' : 'cursor-grab'
        )}
        style={{
          fontSize: `${captionFontSize}px`,
          top: `${positions.top}%`,
          left: `${positions.left}%`,
          padding: `${RESIZE_BOX_PADDING}%`,
          width: `${dimensions.width}%`,
          height: `${dimensions.height}%`
        }}
        data-thumbnail-skip-class="hover:ring-2"
        onMouseDown={handleMouseDown}
        onClick={selectCaptions}
      >
        <div
          ref={textElement}
          className={classnames('flex flex-wrap justify-center whitespace-normal text-center', {
            uppercase: isCapsLock,
            'tracking-tighter': isSmallFontSize
          })}
          style={{
            lineHeight: `${lineHeight}px`,
            height: `${lineHeight}px`,
            marginBottom: 'auto'
          }}
        >
          <div
            className="flex flex-wrap justify-center"
            ref={textContainerRef}
            style={isBackgroundApplied && captionsBackgroundColor ? { backgroundColor: captionsBackgroundColor } : {}}
          >
            <CaptionsWords
              words={words}
              captionFontSize={captionFontSize}
              clipMetadata={clipMetadata}
              animation={clipMetadata.caption_styles?.animation}
              scale={clipMetadata.caption_styles?.scale}
              isPreview={isPreview}
              highlightType={clipMetadata.highlight_type}
            />
          </div>
        </div>

        {areCaptionsSelected && !isPreview && (
          <CaptionsMenu
            isVisible={areCaptionsSelected && !isDragging}
            parentRef={draggableElement.current}
            onPlacementChange={applyPlacement}
            onSizeChange={onSizeChange}
          />
        )}
      </div>
    </div>
  );
}
