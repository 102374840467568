import React, { useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JSConfetti from 'js-confetti';
import { useQuery } from 'react-query';
import { getNavItems } from './constants';
import SessionDetailLayout from './SessionDetailLayout';
import { AI_GENERATED_CLIPS_LIMIT } from '../TranscriptPage/constants';
import { NavItemType } from './components/NavItem';
import { initContent, USER_NOT_ADMIN_OR_IN_ORG_ERROR_MESSAGE } from '@/stores/core';
import { TranscriptContextProvider } from '@/context/TranscriptContext/TranscriptContext';
import { PostAutoGenerateProvider } from '@/context/PostGenerationContext/PostGenerationContext';
import { useAppContext } from '@/context/AppContext/AppContext';
import { loadClips } from '@/libs/clipContentUtil';
import { clearAllClips, overwriteAllClips, setCanGenerateClips } from '@/stores/clip';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import { initUnseenClipIds, unseenClipIds } from '@/stores/unseenClips';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useAnalytics from '@/hooks/useAnalytics';
import useEditFullRecording from '@/hooks/useEditFullRecording';

function SessionDetail() {
  const [loading, setLoading] = useState(true);
  const [isAllClipsLoaded, setIsAllClipsLoaded] = useState(false);
  const [isRecordedContent, setIsRecordedContent] = useState(false);
  const unseenClipsStore = useSyncExternalStore(unseenClipIds.subscribe, unseenClipIds.getSnapshot);
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const { logger } = useAppContext();
  const { getContentUploadById, contentUploadList, initUploadedContent, handleConfettiState } = useContentUploadHook();
  const contentLoaded = useRef(false);
  const navigate = useNavigate();
  const contentUpload = getContentUploadById(broadcastId);
  const { contentState } = contentUpload;
  const { trackFetchedClips } = useAnalytics();
  const { isEditFullRecordingEnabled } = useEditFullRecording();

  function getVideoClips() {
    return loadClips(broadcastId!);
  }

  const showSessionDetailsWithContext = useMemo(() => {
    return (
      (contentState === ContentStatesEnum.ContentReadyForPreview || isRecordedContent) &&
      contentLoaded.current &&
      !loading
    );
  }, [contentState, loading, isRecordedContent]);

  const { refetch: refetchVideoClips } = useQuery({
    queryKey: ['clips', broadcastId],
    queryFn: getVideoClips,
    initialData: [],
    enabled: showSessionDetailsWithContext,
    onSuccess: clips => {
      trackFetchedClips({ count: (clips || []).length });
      if (clips.filter(clip => clip.asset_metadata.source === 'AI').length >= AI_GENERATED_CLIPS_LIMIT) {
        setCanGenerateClips(false);
      }
      overwriteAllClips(clips);
      !isAllClipsLoaded && setIsAllClipsLoaded(true);
      initUnseenClipIds(clips);
    }
  });

  useEffect(() => {
    if (broadcastId && !contentUploadList[broadcastId] && !contentLoaded.current) {
      contentLoaded.current = true;
      initContent(broadcastId)
        .then(res => {
          const { mediaContent } = res;
          if (mediaContent.media_source_type !== 'UPLOAD') {
            setLoading(false);
            setIsRecordedContent(true);
            return;
          }
          const contentDetails = initUploadedContent(res);
          if (contentDetails.contentState === ContentStatesEnum.ContentReadyForPreview) {
            setLoading(false);
            setCanGenerateClips(true);
          } else {
            contentLoaded.current = false;
          }
        })
        .catch(err => {
          const isUserNotInOrgOrNotAdmin =
            err.statusCode === 404 && err.message === USER_NOT_ADMIN_OR_IN_ORG_ERROR_MESSAGE;
          const isAbortError = err.name === 'AbortError';

          if (!(isUserNotInOrgOrNotAdmin || isAbortError)) {
            logger.error('Failed to fetch uploaded content', err);
          }
          navigate('/');
        });
      return;
    }
  }, [contentUploadList, broadcastId, initUploadedContent, navigate, logger]);

  useEffect(() => {
    if (contentState !== ContentStatesEnum.ContentReadyForPreview && broadcastId && contentUploadList[broadcastId]) {
      setLoading(false);
      return;
    }
    if (
      contentState === ContentStatesEnum.ContentReadyForPreview &&
      broadcastId &&
      contentUploadList[broadcastId] &&
      !contentLoaded.current
    ) {
      contentLoaded.current = true;
      setLoading(true);
      setCanGenerateClips(true);
      initContent(broadcastId).then(() => {
        setLoading(false);
      });
    }
  }, [broadcastId, eventId, contentState, contentUploadList, setLoading, loading]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ReloadAllClips, refetchVideoClips);
    return () => {
      EventBus.off(CustomEvents.ReloadAllClips, eventListener);
    };
  });

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ReloadTranscript, loadData);
    return () => {
      EventBus.off(CustomEvents.ReloadTranscript, eventListener);
    };
  });

  useEffect(() => {
    clearAllClips();
  }, []);

  useEffect(() => {
    let jsConfetti;
    if (broadcastId && contentUploadList[broadcastId] && showSessionDetailsWithContext && isAllClipsLoaded) {
      const { showConfetti } = contentUploadList[broadcastId];

      if (showConfetti) {
        jsConfetti = new JSConfetti();
        jsConfetti.addConfetti().then(() => {
          handleConfettiState({ showConfetti: false, contentId: broadcastId });
        });
      }
    }
    return () => {
      jsConfetti?.clearCanvas();
    };
  }, [contentUploadList, showSessionDetailsWithContext, isAllClipsLoaded]);

  function loadData() {
    setLoading(true);
    initContent(broadcastId as string)
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        logger.error(err);
        navigate(`/error/${err?.statusCode}`, { replace: true });
        setLoading(false);
      });
  }

  const handleTranscriptError = (err: any) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  };

  const nav: NavItemType[] = useMemo(() => {
    return getNavItems({
      broadcastId: broadcastId!,
      eventId: eventId!,
      unseenCount: unseenClipsStore.length,
      isEditFullRecordingEnabled
    });
  }, [broadcastId, eventId, unseenClipsStore.length]);

  return showSessionDetailsWithContext ? (
    <TranscriptContextProvider broadcastId={broadcastId} eventId={eventId} onErrorCallback={handleTranscriptError}>
      <PostAutoGenerateProvider>
        <SessionDetailLayout isLoading={loading} navMenu={nav} />
      </PostAutoGenerateProvider>
    </TranscriptContextProvider>
  ) : (
    <SessionDetailLayout isContentUploading={true} isLoading={loading} navMenu={nav} />
  );
}

export default SessionDetail;
